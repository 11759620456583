import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import LineLogo from "../../../assets/LineLogo.png";

function AddField({ onClickHandler, onSave }) {
  const [section, setSection] = useState("");
  const [newFieldName, setnewFieldName] = useState("");

  const DocumentsOptions = () => {
    return [
      "Personal Documents",
      "Educational Documents",
      "Professional Documents",
      "Bank documents",
      "Accubits resume",
    ].map((text) => {
      return <option value={text}>{text}</option>;
    });
  };
  return (
    <div
      style={{
        position: "fixed",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        height: "100vh",
        width: "100%",
        background: " rgba(0, 0, 0, 0.5)",
        zIndex: "1218",
      }}
    >
      <div className="mx-auto sm:px-10 top-10 mt-10 md:container md:mx-auto font-OpenSans justify-between items-center">
        <div
          style={{
            width: window.innerWidth > 600 ? "500px" : window.innerWidth - 20,
            height: window.innerHeight > 700 ? "650px" : "580px",

            position: "fixed",
            background: "white",
            left: "0",
            top: "0",
            bottom: "0",
            right: "0",
            margin: "auto",
            borderRadius: "10px",
          }}
          className="shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] p-2"
        >
          <IoIosClose
            className="float-right cursor-pointer"
            size={25}
            onClick={onClickHandler}
          />
          <div style={{ marginLeft: "35px" }}>
            <div>
              <h1 className="text-lg sm:text-2xl font-bold mb-3 pt-1">
                Add new field
              </h1>
            </div>
            <div>
              <p className="text-sm text-[#5F5F5F] float-left w-3/4  mb-3 mt-3md:ml-2">
                Fill in the fields below
              </p>
            </div>
            <div className="mb-5">
              <img src={LineLogo} alt="" />
            </div>

            <div className=" mb-2 sm:mb-5">
              <label className="text-[#5F5F5F] text-md">Select Section</label>
              <select
                className="text-slate-700  w-11/12 p-2 border mt-2 rounded-lg hover:border-[#3C69DF]"
                placeholder="Select"
                defaultValue={"Select"}
                onChange={(e) => {
                  setSection(e.target.value);
                }}
              >
                <option disabled value="Select">
                  Select
                </option>
                <option value="Personal Details">Personal Details</option>
                <option value="Document Upload">Document Upload</option>
                <option disabled value="Bank Details">
                  Bank Details
                </option>
              </select>
            </div>
            <div className=" mb-2 sm:mb-5">
              <label
                className={` text-md ${
                  section === "Bank Details"
                    ? "text-slate-300 cursor-not-allowed"
                    : "text-[#5F5F5F]"
                }`}
              >
                Select Sub-Section
              </label>
              <select
                // disabled={section === "Bank Details"}
                disabled
                className="text-slate-700  w-11/12 p-2 border mt-2 rounded-lg hover:border-[#3C69DF]"
                placeholder="Select"
              >
                {section === "Document Upload" ? (
                  <DocumentsOptions />
                ) : (
                  <option value="Select">Select</option>
                )}
              </select>
            </div>
            <div className=" mb-2 sm:mb-5">
              <label className="text-[#5F5F5F] text-md mb-1">
                {section === "Document Upload" ? "Document Name" : "Field Name"}
              </label>
              <input
                className="text-slate-700 h-9 w-11/12 p-2 border mt-1 rounded-lg hover:border-[#3C69DF]"
                type="text"
                value={newFieldName}
                onChange={(e) => setnewFieldName(e.target.value)}
              />
            </div>
            <div className=" mb-2 sm:mb-5">
              <label className="text-[#5F5F5F] text-md">
                {section === "Document Upload" ? "Document Type" : "Field Type"}
              </label>
              <select
                disabled
                className="text-slate-700  w-11/12 p-2 border mt-2 rounded-lg hover:border-[#3C69DF]"
                placeholder="Select"
              >
                <option value="Select">Select</option>
              </select>
            </div>
          </div>
          <div>
            <button
              className="w-11/12 h-12 mt-3 absolute bottom-10 mx-2 border rounded-lg bg-[#3C69DF] hover:bg-[#284ba3] text-white text-bold"
              onClick={() => {
                onSave(section, newFieldName);
              }}
            >
              Add new field
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddField;
