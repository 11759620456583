import React from "react";

function Table({ headTitles, children, selectAll, setSelectAll }) {
  return (
    <div class="overflow-auto    sm:rounded-lg">
      <table class="sm:w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-slate-800  ">
          <tr className="border-b border-slate-400">
            {headTitles?.map((title) => (
              <th scope="col" class="py-3 text-center text-slate-600">
                {title === "checkbox" ? (
                  <input
                    type="checkbox"
                    id="checkbox"
                    onClick={() => setSelectAll(!selectAll)}
                  />
                ) : (
                  title
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}

export default Table;
