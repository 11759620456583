import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  exportEmployee,
  deActivateEmployee,
  fetchEmployeeDetails,
} from "../../API/employee";
import Page from "../common/Page";
import AddDocument from "./elements/AddDocument";
import BankingInfo from "./elements/BankingInfo";
import DeactivateEmployee from "./elements/DeactivateEmployee";
import DocumentSection from "./elements/DocumentSection";
import EmployeeDetails from "./elements/EmployeeDetails";
import EmployeeDetailsEdit from "./elements/EmployeeDetailsEdit";
import PersonalInfo from "./elements/PersonalInfo";

function EditEmployee() {
  const { id } = useParams();
  const navigation = useNavigate();
  const [deactivate, setDeactivate] = useState(false);
  const [detailsEdit, setDetailsEdit] = useState(false);
  const [addDocument, setAddDocument] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState();
  const [personalInformation, setPersonalInformation] = useState([]);
  const [bakingDetails, setBankingDetails] = useState();
  const [loading, setLoading] = useState(false);

  function exportData() {
    // console.log(id);
    document.getElementById("export").disabled = true;
    let data = { u1: id };
    exportEmployee(data)
      .then(function (response) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display:none";
        let filename = "export.csv";
        const blob = new Blob([response?.data], { type: "octet/stream" }),
          url = window.URL.createObjectURL(blob);
        // console.log(url);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        document.getElementById("export").disabled = false;
      })
      .catch(function (error) {
        console.log(error);
        document.getElementById("export").disabled = false;
      });
  }
  function getDetails() {
    setLoading(true);
    fetchEmployeeDetails(id)
      .then(function (response) {
        // console.log("Edit employee fetch employee details ",response);
        setEmployeeDetails(response?.data?.result[0]?.[0]);
        // console.log("Set Personal Info ",response?.data?.result[0]?.[0])
        setPersonalInformation(response?.data?.result[0]?.[0]);
        // setPersonalInformation(response?.data?.result[0]?.[0]?.personal_info);
        // console.log("Set personal Information ",response?.data?.result[0]?.[0]?.personal_info);
        setBankingDetails(response?.data?.result[1]);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    getDetails();
  }, []);
  // console.log("edit employee userid",id);

  function deactivateEmployee() {
    deActivateEmployee(id)
      .then(function (response) {
        console.log(response);
        if (response?.data?.msg === "Employee Deactivated") {
          toast.success("Employee Deactivated", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
            hideProgressBar: true,
          });
        } else {
          toast.info(response?.data?.msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
            hideProgressBar: true,
          });
        }
        setDeactivate(false);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          hideProgressBar: true,
        });
      });
  }

  return (
    <Page>
      {loading ? (
        <div className="flex justify-center">
          <svg
            class="inline mr-2 w-8 h-8  animate-spin text-white fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      ) : (
        <>
          {" "}
          <div className="flex flex-col sm:flex-row sm:justify-between ">
            <div className="flex ">
              <p
                onClick={() => navigation("/UserManagement")}
                className=" text-md  sm:text-lg text-[#909090] font-medium cursor-pointer"
              >
                Employee Management
              </p>
              <span className="text-md  sm:text-lg  text-slate-500 font-medium mx-1">
                /
              </span>
              <p className="text-md  sm:text-lg text-slate-700 font-medium">
                {`${employeeDetails?.username ?? "Unavailable"}`}
              </p>
            </div>
            <div className="flex mt-2 sm:mt-0 items-center justify-between sm:justify-start">
              <button
                className="px-8 py-1 text-bold rounded-md text-blue-800 border-2 border-buttonColor hover:bg-slate-100 "
                onClick={() => exportData()}
                id="export"
              >
                Export
              </button>
              <span className="mx-2" />
              <button
                onClick={() => setDeactivate(true)}
                className="px-8 py-1   border-2 border-buttonColor rounded-lg bg-[#3C69DF] hover:bg-[#284ba3] text-white text-bold"
              >
                Deactivate
              </button>
              {deactivate && (
                <DeactivateEmployee
                  onConfirm={() => {
                    deactivateEmployee();
                  }}
                  onCancel={() => setDeactivate(false)}
                />
              )}
            </div>
          </div>
          <div className="p-2 mt-2 ">
            <EmployeeDetails
              setDetailsEdit={setDetailsEdit}
              employeeDetails={employeeDetails}
            />
            {detailsEdit && (
              <EmployeeDetailsEdit
                onClickHandler={() => setDetailsEdit(false)}
                employeeDetails={employeeDetails}
                setEmployeeDetails={setEmployeeDetails}
                getDetails={getDetails}
              />
            )}

            <div className="my-10" />
            <PersonalInfo personalInformation={personalInformation} />
            <div className="my-10" />
            <BankingInfo bakingDetails={bakingDetails} />
            <div className="my-10" />
            <DocumentSection setAddDocument={setAddDocument} />
            {addDocument && (
              <AddDocument onClickHandler={() => setAddDocument(true)} />
            )}
          </div>
        </>
      )}
    </Page>
  );
}

export default EditEmployee;
