import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import Filter from "./Filter";
import SortByDropDown from "./SortByDropDown";

function FilteredSearchBar({
  title,
  sort,
  setSort,
  filter,
  setFilter,
  onSearch,
  searchBar,
  filterDepartment,
  filterEmpType,
}) {
  // console.log("Title ",title);
  // console.log(sort);
  const [value, setValue] = useState("");
  const [debouncedText] = useDebounce(value, 100);

  useEffect(() => {
    console.log(debouncedText);
    if (searchBar) {
      onSearch(debouncedText);
    }
  }, [debouncedText]);

  return (
    <div className="sm:flex sm:items-center sm:mt-5">
      <form className="w-full sm:w-5/6">
        <label
          for="default-search"
          class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
        >
          Search
        </label>
        {searchBar ? (
          <div class="relative">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block p-4 pl-10 w-full text-sm text-gray-900  rounded-lg border focus:outline-none"
              placeholder={title}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            {/* <button
              type="submit"
              class="text-white absolute right-2.5 bottom-2.5  hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2  bg-buttonColor"
            >
              Reset
            </button> */}
          </div>
        ) : null}
      </form>
      <span className="sm:mx-2"></span>
      <div className="flex my-3 sm:my-0 justify-between">
        <SortByDropDown sort={sort} setSort={setSort} />

        <span className="mx-2"></span>
        <Filter
          filter={filter}
          setFilter={setFilter}
          filterDepartment={filterDepartment}
          filterEmpType={filterEmpType}
        />
      </div>
    </div>
  );
}

export default FilteredSearchBar;
