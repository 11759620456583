import React from "react";
import EditSection from "../../common/EditSection";
import ListItem from "../../common/ListItem";

function BankingInfo({ bakingDetails }) {
  return (
    <EditSection title="Banking Info">
      <ListItem keyword="Bank" value={bakingDetails?.bank_name ?? "Nil"} />
      <ListItem
        keyword="Account Name"
        value={bakingDetails?.account_name ?? "Nil"}
      />
      <ListItem
        keyword="Account Number"
        value={bakingDetails?.account_no ?? "Nil"}
      />
      <ListItem keyword="IFSC CODE" value={bakingDetails?.ifsc_code ?? "Nil"} />
      <ListItem
        keyword="Branch Address"
        value={bakingDetails?.branch_name ?? "Nil"}
      />
    </EditSection>
  );
}

export default BankingInfo;
