import React, { useEffect, useRef, useState } from "react";
import { fetchEmpType } from "../../API/employeeType";

function EmployeeTypeDropDown({
  dept,
  value,
  onChange,
  fullWidth = false,
  fixed,
}) {
  // console.log(dept);
  const [showAddDept, setShowAddDept] = useState(false);
  const deptRef = useRef(null);
  const [empTypes, setEmpTypes] = useState([]);

  function getEmployeeTypes() {
    console.log(dept);
    fetchEmpType(dept)
      .then(function (response) {
        // console.log(response, " get emp types");
        setEmpTypes(response?.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (dept) {
      getEmployeeTypes(dept);
    }
  }, [dept]);

  useEffect(() => {
    if (value) {
      if (deptRef && deptRef?.current) {
        deptRef?.current?.focus();
      }
    }
  }, [value]);

  function AddEmpType() {
    return (
      <>
        <div>
          <label className="text-[#5F5F5F] text-md mb-1">
            New Employee Type
          </label>
          <input
            ref={deptRef}
            className="text-slate-700 h-9 w-11/12 p-2 border mt-2 rounded-lg hover:border-[#3C69DF]"
            type="text"
            placeholder="Enter Employee type"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>
      </>
    );
  }

  if (fixed) {
    return (
      <div
        className={
          fullWidth ? null : "sm:w-96 max-w-xs sm:max-w-lg  mb-2 sm:mb-5"
        }
      >
        <label className="text-[#5F5F5F] text-md mb-1">Employee type</label>
        <input
          disabled
          ref={deptRef}
          className="text-slate-700 h-9 w-11/12 p-2 border mt-2 rounded-lg hover:border-[#3C69DF]"
          type="text"
          placeholder="Enter employee type"
          value={value}
        />
      </div>
    );
  }

  return (
    <div
      className={
        fullWidth ? null : "sm:w-96 max-w-xs sm:max-w-lg  mb-2 sm:mb-5"
      }
    >
      {showAddDept ? (
        <AddEmpType />
      ) : (
        <>
          <label className=" block text-[#5F5F5F] text-md ">
            Employee Type
          </label>
          <select
            ref={deptRef}
            className="text-slate-700  w-11/12 p-2 border mt-2 rounded-lg hover:border-[#3C69DF]"
            placeholder="Select"
            value={value}
            defaultValue="Select"
            onChange={(e) => {
              if (e.target.value === "new") {
                setShowAddDept(true);
              } else {
                onChange(e.target.value);
              }
            }}
          >
            {dept ? (
              <>
                <option disabled value="Select">
                  Select
                </option>
                <option value="new">+ Add new</option>
                {empTypes?.map(({ employee_type }) => {
                  return <option value={employee_type}>{employee_type}</option>;
                })}
              </>
            ) : (
              <option disabled value="Select">
                Select
              </option>
            )}
          </select>
        </>
      )}
    </div>
  );
}

export default EmployeeTypeDropDown;
