import axios from "axios";
import { baseAddress } from "./config";

const token = localStorage.getItem("JWT");

const today_date = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "/" + mm + "/" + dd;
  return today;
};

export function createEmployee({
  email,
  empName,
  dept,
  empType,
  empId,
  doj,
  password,
  empStatus,
  onBoardingStatus,
  accessType,
  hrId,
  roleId,
}) {
  return axios.post(
    `${baseAddress}/create_employee`,
    {
      email: email,
      name: empName,
      dept: dept,
      emp_type: empType,
      emp_id: empId,
      doj: today_date,
      password: password,
      emp_status: "Active",
      onboarding_status: "yet to login",
      access_type: "user",
      hr_id: localStorage.getItem("HRid"),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function fetchAllEmployees() {
  // console.log("Fetch employee ",localStorage.getItem("JWT"))
  return axios.get(
    `${baseAddress}/fetch_all_employee_details`,

    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function fetchEmployeeDetails(id) {
  // console.log("fetch Employee API ",id);
  // console.log("Jwt ",localStorage.getItem("JWT"));
  return axios.post(
    `${baseAddress}/fetch_employee_detail_one`,
    {
      user_id: id,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function updateDocumentStatus(id, field, status, rejectionComment) {
  return axios.post(
    `${baseAddress}/document_verification`,
    {
      user_id: id,
      doc_field_name: field,
      status,
      comment: JSON.stringify(rejectionComment),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function editEmployee(userId, empId, dept, empType) {
  return axios.post(
    `${baseAddress}/editEmployee`,
    {
      user_id: userId,
      emp_id: empId,
      emp_dept: dept,
      emp_type: empType,
    },
    {
      headers: {
        JWT: token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function filterEmployee(dept) {
  console.log(JSON.stringify(dept));
  return axios.post(
    `${baseAddress}/filter_dept_type`,
    {
      depttype: JSON.stringify(dept),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function filterEmployeeType(empType) {
  console.log(empType);
  return axios.post(
    `${baseAddress}/filter_employee_type`,
    {
      employeetype: JSON.stringify(empType),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function deActivateEmployee(userId, rLetter, eLetter) {
  console.log(userId);
  return axios.patch(
    `${baseAddress}/deactivate`,
    {
      user_id: userId,
      relievingLetter: rLetter,
      experienceLetter: eLetter,
      status: "Deactive",
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function verifyEmployee(id) {
  return axios.post(
    `${baseAddress}/verifyEmployee`,
    {
      user_id: id,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function sortEmployee(option) {
  return axios.post(
    `${baseAddress}/sort_employee`,
    {
      sortType: option,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
      },
    }
  );
}

export function exportEmployee(employees) {
  // console.log(employees);
  return axios.post(
    `${baseAddress}/export_employee`,
    {
      u_ids: JSON.stringify(employees),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
      },
    }
  );
}

export function deleteEmployee(userId) {
  return axios.post(
    `${baseAddress}/delete_employee`,
    {
      user_id: userId,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
      },
    }
  );
}

export function allEmployees() {
  // console.log("Fetch employee ",localStorage.getItem("JWT"))
  return axios.post(
    `${baseAddress}/All_dept`,
    {
      JWT: localStorage.getItem("JWT"),
    },

    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function filterBy(dept, empType) {
  // console.log("Fetch employee ",localStorage.getItem("JWT"))
  console.log(dept, empType);
  return axios.post(
    `${baseAddress}/filter_dept_emp_type`,
    {
      dept: JSON.stringify(dept),
      emp_type: JSON.stringify(empType),
    },

    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}
