import axios from "axios";
import { baseAddress } from "./config";

const HRtoken = localStorage.getItem("JWT");
const HRid = localStorage.getItem("HRid");

export function fetchDepartments() {
  return axios.get(
    `${baseAddress}/fetch_department`,

    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function addDepartment(dept) {
  return axios.post(
    `${baseAddress}/add_new_department`,
    {
      hr_id: localStorage.getItem("HRid"),
      dept_name: dept,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}
