import React from "react";
import uparrow from "../../assets/uparrow.png";
const Footer = () => {
  return (
    <footer class="h-20 xs:h-2/4 mt-5 border-2 flex justify-around">
      <div className="mt-4 flex lg:flex-row lg:gap-x-10 lg:text-base sm:text-xs xs:flex-col xs:text-xs xs:mx-5 xs:my-2">
        <p className="xs:mx-5"> Upkeep</p>
        <li className=""> Privacy</li>
        <li className=""> Terms</li>
        <li className=""> Company details</li>
      </div>
      <div className="flex lg:flex-row xs:flex-col gap-x-4 xs:grid-cols-1 xs:my-2 xs:mx-10  md:mr-7 mt-4 xs:text-xs sm:text-xs lg:text-base">
        <p className="">English (IN) </p>
        <div className="flex">
          <p className="">Support & resources</p>
          <img src={uparrow} alt="#" className="h-1.5 w-4 pl-1 mt-2" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
