// import React, { useEffect, useState } from "react";
// import { RiMoreFill, RiDeleteBin7Line } from "react-icons/ri";
// import { MdModeEdit } from "react-icons/md";
// import { useNavigate } from "react-router-dom";
// import { fetchAllEmployees } from "../../../API/employee";

// function EmployeeList({ employees, setEmployees }) {
//   const [ShowActions, setShowActions] = useState("");

//   const navigation = useNavigate();

//   const[page,setPage]=useState(1);
//   // console.log("employee ",employees);

//   // useEffect(() => {
//   //   document.body.addEventListener("click", (event) => {
//   //     if (event?.path?.[1]?.id !== "actions") {
//   //       setShowActions(false);
//   //     }
//   //   });
//   // }, []);

//   function DeleteEmployee(userid)
//   {
//     console.log(userid);
//   }

//   const Actions = ({ user_id }) => {
//     return (
import React, { useEffect, useState } from "react";
import { RiMoreFill, RiDeleteBin7Line } from "react-icons/ri";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { deleteEmployee, fetchAllEmployees } from "../../../API/employee";

function EmployeeList({
  employees,
  setEmployees,
  selectedEmployeeIds,
  setSelectedEmployeeIds,
}) {
  const [ShowActions, setShowActions] = useState(false);
  const [userIDs, setUserIDs] = useState([]);
  let counter = 0;
  let i,
    j = 0;

  const navigation = useNavigate();

  const [page, setPage] = useState(1);
  // console.log("employee ",employees);

  // useEffect(() => {
  //   document.body.addEventListener("click", (event) => {
  //     if (event?.path?.[1]?.id !== "actions") {
  //       setShowActions(false);
  //     }
  //   });
  // }, []);

  function delete_Employee(user_id) {
    setShowActions("");
    deleteEmployee(user_id)
      .then(function (response) {
        console.log(response);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const Actions = ({ user_id }) => {
    return (
      <div class="absolute right-10 z-50 w-56 py-2 mt-2 overflow-hidden bg-white rounded-md shadow-xl ">
        <div
          onClick={() => navigation(`/UserManagement/edit/${user_id}`)}
          className="flex  items-center block cursor-pointer px-4 py-3 capitalize transition-colors duration-200  hover:bg-gray-100"
        >
          <MdModeEdit />
          <span className="mx-1" />
          <p class=" text-sm font-medium text-gray-600  ">Edit</p>
        </div>
        <div
          className="flex  items-center block cursor-pointer px-4 py-3 capitalize transition-colors duration-200  hover:bg-gray-100"
          onClick={() => delete_Employee(user_id)}
        >
          <RiDeleteBin7Line />
          <span className="mx-1" />
          <p class=" text-sm font-medium text-gray-600">Delete</p>
        </div>
      </div>
    );
  };

  const selectPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= employees.length &&
      selectedPage !== page
    )
      setPage(selectedPage);
    for (i = 0; i < employees.length; i++) {
      if (page === page + i) {
        j = j + 20;
      }
    }
  };

  const handleCheckboxChange = (id, countervalue, check) => {
    if (!check) {
      const newstate = Object.keys(selectedEmployeeIds)
        .filter((k) => k !== countervalue)
        .reduce((acc, k) => ({ ...acc, [k]: selectedEmployeeIds[k] }), {});

      // console.log(newstate);
      setSelectedEmployeeIds(newstate);

      // console.log(selectedEmployeeIds);
    } else {
      setSelectedEmployeeIds({ ...selectedEmployeeIds, [countervalue]: id });
      // console.log(selectedEmployeeIds);
    }
    // console.log(selectedEmployeeIds);
  };
  return (
    <>
      {employees
        .slice(page * 20 - 20, page * 20)
        ?.map(
          ({
            user_id,
            username,
            emp_id,
            date_of_joining,
            dept,
            emp_type,
            email,
            emp_status,
          }) => {
            counter++;
            return (
              <tr className="bg-white text-center border-b border-slate-300  text-slate-700">
                <th scope="row" className="py-4 font-medium whitespace-nowrap">
                  <input
                    type="checkbox"
                    id={counter}
                    value={user_id}
                    onClick={(e) =>
                      handleCheckboxChange(
                        user_id,
                        e.target.id,
                        e.target.checked
                      )
                    }
                  />
                </th>
                <td className="py-4">{username}</td>
                <td className="py-4">{emp_id}</td>
                <td className="py-4">{date_of_joining}</td>
                <td className="py-4">
                  {dept.charAt(0).toUpperCase() + dept.slice(1)}
                </td>
                <td className="py-4 ">
                  {emp_type.charAt(0).toUpperCase() + emp_type.slice(1)}
                </td>
                <td className="py-4 ">{email}</td>
                <td className="py-4 ">{emp_status}</td>
                <td id="actions" className="py-4 px-8  ">
                  <RiMoreFill
                    className="cursor-pointer"
                    onClick={() =>
                      ShowActions === user_id
                        ? setShowActions("")
                        : setShowActions(user_id)
                    }
                    size="1.5rem"
                  />
                  {ShowActions === user_id ? (
                    <>
                      <Actions user_id={user_id} />
                    </>
                  ) : null}
                </td>
              </tr>
            );
          }
        )}
      {employees.length > 0 && (
        <div className="p-3">
          <span
            className={page > 1 ? "visible cursor-pointer" : "invisible"}
            onClick={() => selectPageHandler(page - 1)}
          >
            ◀
          </span>
          {[...Array(Math.ceil(employees.length / 20))].map((_, i) => {
            return (
              <span
                className={`p-2 mx-1 cursor-pointer border rounded-lg ${
                  page === i + 1 ? "bg-gray-300" : ""
                }`}
                onClick={() => selectPageHandler(i + 1)}
                key={i}
              >
                {i + 1}
              </span>
            );
          })}
          <span
            className={
              page < Math.ceil(employees.length / 20)
                ? "visible cursor-pointer "
                : "invisible"
            }
            onClick={() => selectPageHandler(page + 1)}
          >
            ▶
          </span>
        </div>
      )}
    </>
  );
}

export default EmployeeList;
