import React, { useEffect, useState } from "react";
import { RiArrowDropRightLine, RiArrowDropDownLine } from "react-icons/ri";
import { fetchDepartments } from "../../API/department";
import { fetchEmpType } from "../../API/employeeType";

function Filter({ filter, setFilter, filterDepartment, filterEmpType }) {
  const [showFilter, setShowFilter] = useState(false);
  const [active, setActive] = useState(-1);
  const [departments, setDepartments] = useState([]);
  const [empTypes, setEmpTypes] = useState([]);
  let dept = [],
    emp = [];
  // useEffect(() => {
  //   document.body.addEventListener("click", (event) => {
  //     if (event?.path?.[3]?.id !== "filter") {
  //       setShowFilter(false);
  //     }
  //   });
  // }, []);

  function getEmployeeTypes(dept) {
    console.log(dept.department);
    fetchEmpType(dept.department)
      .then(function (response) {
        // localStorage.setItem("emptype",JSON.stringify(response?.data?.result));
        setEmpTypes(response?.data?.result);
        // console.log(JSON.parse(localStorage.getItem("emptype")));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getDepartments() {
    setDepartments(JSON.parse(localStorage.getItem("department")));
    // console.log(departments);
  }

  useEffect(() => {
    setDepartments(JSON.parse(localStorage.getItem("department")));
    setEmpTypes(JSON.parse(localStorage.getItem("EmpType")));
  }, []);

  function applyFilter() {
    setShowFilter(!showFilter);
    setFilter({ dept: dept, empType: emp });
  }

  function reset() {
    setFilter({ dept: [], empType: [] });
    setShowFilter(!showFilter);
    window.location.reload();
  }

  function FilterDetails(name, type, checked) {
    if (checked) {
      if (type === "Department") {
        dept.push(name);
        console.log(dept);
      } else {
        emp.push(name);
      }
    } else {
      if (type === "Department") {
        dept = dept.filter((value) => value !== name);
      } else {
        emp = emp.filter((value) => value !== name);
      }
    }
    console.log(emp, dept);
  }

  const FilterCheckBox = ({ name, type }) => {
    // console.log(name,type);
    return (
      <div id="filter" class="flex items-center px-4 py-1 ml-2  ">
        <input
          id="filter"
          type="checkbox"
          onClick={(e) => FilterDetails(name, type, e.target.checked)}
          class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-slate-300 focus:ring-blue-500 focus:ring-2  "
        />
        <label
          for="default-checkbox"
          class="ml-2 text-sm font-medium text-gray-600  "
        >
          {name}
        </label>
      </div>
    );
  };

  const FilterItem = ({ title, index, values }) => {
    // console.log(title);
    // console.log(index);
    // console.log(values);
    return (
      <div>
        <div className="capitalize transition-colors duration-200 cursor-pointer px-4 py-3 flex justify-between items-center block hover:bg-gray-100 ">
          <p class=" text-md font-medium text-gray-600  ">{title}</p>
          <section
          // onClick={() => {
          //   if (active === index) {
          //     setActive(-1);
          //   } else {
          //     setActive(index);
          //   }
          // }}
          >
            {/* {active === index ? (
            <RiArrowDropDownLine size="1.5rem" className="text-slate-700" />
          ) : (
            <RiArrowDropRightLine size="1.5rem" className="text-slate-700" />
          )} */}
          </section>
        </div>

        {/* {active === index ? ( */}
        <div id="filter" className="px-2">
          {values?.map(({ department, employee_type }) => {
            // console.log(department,employee_type);
            return (
              <FilterCheckBox name={department ?? employee_type} type={title} />
            );
          })}
        </div>
        {/* ) : null}  */}
      </div>
    );
  };
  return (
    <div id="filter" class="flex justify-center font-OpenSans">
      <div id="filter" class="relative inline-block ">
        <button
          onClick={() => setShowFilter(!showFilter)}
          id="filter"
          class="relative whitespace-nowrap z-10 flex items-center p-3 text-sm text-gray-600 bg-white border border-slate-200 rounded-md focus:border-blue-500 focus:ring-opacity-40  focus:ring-blue-300  focus:ring   focus:outline-none"
        >
          <span class="mx-1 text-base font-semibold">Filter By</span>
          <svg
            class="w-5 h-5 mx-1"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z"
              fill="currentColor"
            ></path>
          </svg>
        </button>
        {showFilter ? (
          <div
            id="filter"
            class="absolute right-0 z-20 w-56 py-2 mt-2 overflow-hidden bg-white rounded-md shadow-xl"
          >
            <div id="filter" class="mx-1 p-3 -mt-2 flex justify-between">
              <h1 class="text-sm font-semibold text-gray-400">
                Filter By
              </h1>
              <div
                class="text-sm font-medium text-blue-500 cursor-pointer hover:underline "
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </div>
            </div>
            {filterDepartment && (
              <FilterItem
                id="filter"
                title="Department"
                index={0}
                values={departments}
              />
            )}
            {filterEmpType && (
              <FilterItem
                id="filter"
                title="Employee type"
                index={1}
                values={empTypes}
              />
            )}
            <button
              className="w-full text-blue-500 font-medium my-2"
              onClick={() => applyFilter()}
            >
              Apply Filter
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Filter;
