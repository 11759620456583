import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import CheckBoxListItem from "../../common/CheckBoxListItem";
import EditSection from "../../common/EditSection";

const fieldsArray = [
  { text: "First Name", name: "fName" },
  { text: "Middle Name", name: "mName" },
  { text: "Last Name", name: "lName" },
  { text: "DOJ", name: "doj" },
  { text: "Alternate mail ID", name: "email" },
  { text: "Phone no", name: "phone" },
  { text: "Emergency Contact No", name: "eContact" },
  { text: "Blood Group", name: "bloodGroup" },
  { text: "Date of Birth", name: "dob" },
  { text: "Gender", name: "gender" },
  { text: "Current Address", name: "currentAddress" },
  { text: "Permanent Address", name: "permanentAddress" },

  { text: "PAN Number", name: "pan" },
  {
    text: "Are you a registered Organ Donor? If yes please state details, else please enter no.",
    name: "organDonor",
  },
  {
    text: "Do you have any pre existing medical conditions? If yes please mention the medical condition, else please mention no.",
    name: "medicalConditions",
  },
  {
    text: "Are you allergic to certain foods, medications, animals, insects, etc? If so please state details, else please enter no.",
    name: "allergicFood",
  },
];

function PersonalInfo({
  setPersonalDetails,
  personalDetails,
  newPersonalInfoFields,
  setNewPersonalInfoFields,
}) {
  // console.log({personalDetails}, "personal");
  // console.log({newPersonalInfoFields}, "extrapersonal");

  return (
    <EditSection
      title="Personal Information"
      subtitle="Select from the list below"
    >
      {fieldsArray?.map(({ text, name }) => {
        return (
          <CheckBoxListItem
            label={text}
            checked={personalDetails?.[name]}
            onChange={(e) => {
              setPersonalDetails({
                ...personalDetails,
                [name]: e.target.checked,
              });
            }}
          />
        );
      })}
      {newPersonalInfoFields?.map(({ text, name }) => {
        return (
          <CheckBoxListItem
            label={text}
            checked={personalDetails?.[name]}
            onChange={(e) => {
              setPersonalDetails({
                ...personalDetails,
                [name]: e.target.checked,
              });
            }}
          />
        );
      })}
    </EditSection>
  );
}

export default PersonalInfo;
