import React, { useState } from 'react';
import DetailsBarTwo from "../PageThree/DetailsBarTwo";
import CompletionBarTwo from '../PageThree/CompletionBarTwo';
import InfoTwo from '../PageThree/InfoTwo';
import Navbar from '../PageTwo/Navbar';

const PageTwo = () => {
  //const [menuDrop, setMenuDrop] = useState(false);
  const [complete, setComplete] = useState(0);

  return (
    <>
      <Navbar />
      <DetailsBarTwo complete={complete} />
      <CompletionBarTwo complete={complete} />
      <InfoTwo complete={complete} setComplete={setComplete} />
    </>
  );
};

export default PageTwo