import axios from "axios";
import { baseAddress } from "./config";

const HRtoken = localStorage.getItem("JWT");
const HRid = localStorage.getItem("HRid");
export function addRole(
  department,
  employeeType,
  personalDetails,
  documents,
  banking_details
) {
  let bankDetails = banking_details === "true";
  // console.log(
  //   banking_details,
  //   typeof banking_details,
  //   bankDetails
  // );
  return axios.post(
    `${baseAddress}/createRole`,
    {
      department: department,
      employeeType: employeeType,
      personalDetails: JSON.stringify(personalDetails),
      documents: JSON.stringify(documents),
      bankingRequirement: bankDetails,
      hr_id: localStorage.getItem("HRid"),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function fetchRoles() {
  return axios.get(
    `${baseAddress}/fetch_all_role`,

    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function fetchRole_roleid(id) {
  console.log(id);
  return axios.post(
    `${baseAddress}/fetch_role_roleid`,
    {
      roleId: id,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function fetchRole(id) {
  console.log(id);
  return axios.post(
    `${baseAddress}/fetch_role`,
    {
      user_id: id,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function editRole(
  department,
  employeeType,
  personalDetails,
  documents,
  hr_id
) {
  console.log(
    "edit role",
    department,
    employeeType,
    personalDetails,
    documents
  );
  return axios.post(
    `${baseAddress}/edit_role`,
    {
      dept: department,
      emp_type: employeeType,
      personalDetails: JSON.stringify(personalDetails),
      documents: JSON.stringify(documents),
      hr_id: localStorage.getItem("HRid"),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function sortRole(option) {
  return axios.post(
    `${baseAddress}/sort_role`,
    {
      sortType: option,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
      },
    }
  );
}

export function filterRole(dept) {
  return axios.post(
    `${baseAddress}/filter_role`,
    {
      dept: dept,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function filterRoleEmp(emp, dept) {
  return axios.post(
    `${baseAddress}/filter_role_dept_emp`,
    {
      dept: JSON.stringify(dept),
      emp_type: JSON.stringify(emp),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function addNewField(section, dept, empType, data) {
  return axios.post(
    `${baseAddress}/add_new_field_role`,
    {
      section: section,
      dept: dept,
      emp_type: empType,
      hr_id: localStorage.getItem("HRid"),
      data: JSON.stringify(data),
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function deleteRole(id) {
  return axios.post(
    `${baseAddress}/delete_role`,
    {
      role_id: id,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}
