import React, { useState } from "react";
import EditSection from "../../common/EditSection";
import { MdDone, MdClose, MdOutlineModeComment } from "react-icons/md";
import RejectDocument from "./RejectDocument";
import ReasonForReject from "./ReasonForReject";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { updateDocumentStatus } from "../../../API/employee";
import { fetchEmployeeDetails } from "../../../API/employee";

function ViewDocument({ documents = {}, setDocument }) {
  const [opened, setOpened] = useState(0);
  const [showRejectDocument, setShowRejectDocument] = useState(false);
  const [showRejectReason, setShowRejectReason] = useState(false);
  const [docArray, setDocArray] = useState([]);
  const [statusArray, setStatusArray] = useState([]);
  const [openedDoc, setOpenedDoc] = useState({ document: "", text: "" });
  const { id } = useParams();
  const [documentName, setDocumentName] = useState("");
  const [rerender, setRerender] = useState(false); //Dummy state for re-render the component

  // Get details of documents of the users
  function getDetails(id) {
    console.log("getDetails", id);
    fetchEmployeeDetails(id)
      .then(function (response) {
        setDocument(response?.data?.result[2]?.[0]?.documents);
        setRerender(!rerender);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    // console.log("Update");
    const docCopy = [];
    const statusCopy = [];
    if (Object.keys(documents).length > 0) {
      Object.keys(documents).forEach((el) => {
        if (!el.includes("status")) {
          docCopy.push({
            text: el,
            document: documents[el],
          });
        } else {
          statusCopy.push({
            text: el,
            status: documents[el],
          });
        }
      });

      setDocArray(docCopy);
      setStatusArray(statusCopy);
      setOpenedDoc({
        document: docCopy?.[0]?.document,
        text: docCopy?.[0]?.text,
      });
    }
    // console.log(docArray.length);
  }, [rerender]);

  // Accept and reject status update

  const handleUpdate = async (status) => {
    console.log(id, status, openedDoc.text);
    if (status === 0) setDocumentName(openedDoc.text);
    try {
      const response = await updateDocumentStatus(id, openedDoc.text, status);
      console.log("response", response);
      getDetails(id);
    } catch (err) {
      console.log("err :>> ", err);
    }
  };

  return (
    <EditSection title="Documents">
      {docArray.length !== 0 ? (
        <div className="flex mt-2 justify-between" id="document_list">
          <div>
            <ul>
              {docArray.map((t, index) => {
                // console.log(t,index);
                return (
                  <li className="my-1 flex justify-between mr-4">
                    <div
                      onClick={() => {
                        setOpened(index);
                        setOpenedDoc({ document: t.document, text: t.text });
                      }}
                      className={`flex whitespace-nowrap  rounded-sm px-4 py-2 font-medium text-sm text-slate-400 cursor-pointer ${
                        opened === index ? "bg-blue-100 text-blue-500" : ""
                      }`}
                    >
                      {index + 1}
                      <span className="mx-1 sm:mx-2" />
                      {t.text}
                    </div>

                    {statusArray.map((el) => {
                      // console.log(el.status,`${t.text}_status`);
                      if (el.text === `${t.text}_status`) {
                        return (
                          <div className="px-4 py-2">
                            {el.status === 1 ? <MdDone color="green" /> : null}
                            {el.status === 0 ? (
                              <div className="flex">
                                <MdClose color="red" />
                                {/* <span className="mx-1" /> */}
                                {/* <MdOutlineModeComment
                                className="cursor-pointer"
                                color="blue"
                                onClick={() => setShowRejectReason(true)}
                              /> */}
                              </div>
                            ) : null}
                            {el.status === undefined ? "-" : null}
                          </div>
                        );
                      }
                    })}
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className="grow-[3] relative rounded bg-slate-200 flex justify-center"
            style={{ minHeight: "27rem" }}
          >
            {/* Document show */}
            <object
              // width="100%"
              height="450"
              className="absolute top-2 w-full"
              data={openedDoc.document}
              type="application/pdf"
            >
              {" "}
            </object>

            {/* Arrow button left and right */}
            <div className=" absolute left-0 w-full self-center  ">
              <div
                onClick={() => {
                  if (opened > 0) {
                    setOpened(opened - 1);
                    setOpenedDoc({
                      document: docArray?.[opened - 1]?.document,
                      text: docArray?.[opened - 1]?.text,
                    });
                  }
                }}
                className=" absolute left-2 "
              >
                <BsArrowLeft
                  color="grey"
                  className=" bg-gray-50 h-10 w-10 p-2 rounded-full cursor-pointer  flex justify-between items-center  "
                  size="2rem"
                />
              </div>
              <div
                onClick={() => {
                  if (opened + 1 < docArray.length) {
                    setOpened(opened + 1);
                    setOpenedDoc({
                      document: docArray?.[opened + 1]?.document,
                      text: docArray?.[opened + 1]?.text,
                    });
                  }
                }}
                className=" absolute right-2"
              >
                <BsArrowRight
                  color="grey"
                  className=" bg-gray-50 h-10 w-10 p-2 rounded-full cursor-pointer  flex justify-between items-center  "
                  size="2rem"
                />
              </div>
            </div>

            {/* accept and reject button */}

            <div className=" flex self-end m-1 z-10">
              <div>
                <MdDone
                  color="green"
                  className=" bg-gray-50 h-10 w-10 p-2 rounded-full cursor-pointer  flex justify-between items-center  "
                  size="2rem"
                  onClick={() => handleUpdate(1)}
                />
                <p className="text-sm mt-1">Accept</p>
              </div>
              <span className="mx-2" />
              <div
                onClick={() => {
                  // handleUpdate(0);
                  setShowRejectDocument(true);
                }}
              >
                <MdClose
                  className=" bg-gray-50 h-10 w-10 p-2 rounded-full cursor-pointer  flex justify-between items-center  "
                  color="red"
                  size="2rem"
                  // onClick={() => {
                  //   setShowRejectDocument(true);
                  // }}
                />
                <p className="text-sm mt-1">Reject</p>
              </div>
            </div>
            {showRejectDocument ? (
              <RejectDocument
                onClickHandler={() => setShowRejectDocument(false)}
                openedDoc={openedDoc.text}
                getDetails={getDetails}
              />
            ) : null}
            {/* {showRejectReason ? (
            <ReasonForReject
              onClickHandler={() => setShowRejectReason(false)}
              openedDoc={openedDoc.text}
            />
          ) : null} */}
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <h1 className="text-xl text-slate-400">
            Documents that have not been uploaded
          </h1>
        </div>
      )}
    </EditSection>
  );
}

export default ViewDocument;
