import React, { useState } from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";
import SideBar from "./SideBar";

function Page({ children }) {
  const [showSideBar, setShowSideBar] = useState(true);
  return (
    <div className="  h-screen bg-slate-200">
      <NavBar setShowSideBar={setShowSideBar} />
      <div style={{ height: "92%" }} className=" flex w-screen  ">
        {showSideBar && <SideBar />}

        <div className=" h-[95%] overflow-auto relative bg-white my-3 mx-2 sm:mx-5 py-2 sm:py-5 px-2 sm:px-6 rounded-md w-full">
          {children}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Page;
