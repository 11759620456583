import React, { useEffect, useState } from "react";
import Page from "../common/Page";

import FilteredSearchBar from "../common/FilteredSearchBar";

import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";

import Table from "../common/Table";
import CreateEmployee from "../EmployeeManagement/CreateEmployee";
import RequestList from "./RequestList";
import {
  sortEmployee,
  fetchAllEmployees,
  filterEmployee,
  filterEmployeeType,
  filterBy,
} from "../../API/employee";

const ApprovalRequests = () => {
  const [form, setForm] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [skeleton, setSkeleton] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ dept: "", empType: "" });
  const [sort, setSort] = useState("Alphabetical");

  // useEffect(() => {
  //   // getAllEmployees();
  //   console.log({employees});
  // }, []);

  function Filter_By(dept, emptype) {
    setLoading(true);
    filterBy(dept, emptype)
      .then(function (response) {
        console.log(response);
        if (response?.data?.msg === "Employee doesnot exist") {
          setEmployees([]);
        } else {
          console.log(response);
          setEmployees(response?.data?.result ?? []);
          setLoading(false);
          setSkeleton(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    // console.log("get Filtered role emptype")
    // console.log(filter.empType,filter.dept);
    // if (filter.empType !=[]) {
    if (filter.empType.length === 0 && filter.dept.length === 0) {
      // console.log(filter.empType.length);
      getSortedEmployee();
    } else {
      // console.log("filteremptype");
      Filter_By(filter.dept, filter.empType);
    }
    // }
  }, [filter]);

  function getFilteredRolesEmpType() {
    setLoading(true);
    filterEmployeeType(filter.empType)
      .then(function (response) {
        if (response?.data?.msg === "Role does not exist") {
        } else {
          setEmployees(response?.data?.result);
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(true);
        console.log(error);
      });
  }

  //Sorting record
  useEffect(() => {
    // console.log({employees});
    if (sort) {
      getSortedEmployee();
    }
  }, [sort]);

  function getSortedEmployee() {
    setLoading(true);
    sortEmployee(sort)
      .then(function (response) {
        // console.log(response);
        if (response?.data?.msg === "Employee doesnot exist") {
          setEmployees([]);
        } else {
          // console.log(response);
          setEmployees(response?.data?.msg ?? []);
        }
        // console.log(response?.data?.msg ?? []);
        setLoading(false);
        setSkeleton(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getSearchResult(value) {
    if (!value) {
      getSortedEmployee();
    } else {
      setEmployees(() => {
        return employees?.filter((emp) => {
          return emp?.username?.includes(value) || emp?.emp_id?.includes(value);
        });
      });
    }
  }

  return (
    <Page>
      <div className="my-1  sm:my-3 sm:flex sm:justify-between">
        <p class="text-lg font-OpenSans font-semibold">Approval Requests</p>
      </div>
      <FilteredSearchBar
        sort={sort}
        setSort={setSort}
        filter={filter}
        setFilter={setFilter}
        title="Enter Name or Employee Id"
        onSearch={(value) => {
          getSearchResult(value);
        }}
        searchBar
        filterDepartment
        filterEmpType
      />
      <Table
        headTitles={[
          "Full Name",
          "Employee ID",
          "DOJ",
          "Department",
          "Employee Type",
          "Email",
          "Status",
          "Last Updated",
        ]}
      >
        {!skeleton && (
          <Skeleton
            count={10}
            style={{
              display: "grid",
              height: 45,
              width: 1500,
              marginTop: "1rem",
            }}
          />
        )}
        {skeleton && (
          <RequestList employees={employees} setEmployees={setEmployees} />
        )}
      </Table>
      {employees.length === 0 && (
        <div className="h-screen w-full mt-5">
          <div className="flex justify-center items-center">
            <p className="font-bold text-2xl text-gray-500">No Record Found</p>
          </div>
        </div>
      )}
    </Page>
  );
};
export default ApprovalRequests;
