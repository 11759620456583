import React from "react";
import EditSection from "../../common/EditSection";
import ListItem from "../../common/ListItem";

function EmployeeDetails({ setDetailsEdit, employeeDetails }) {
  function onEditClick() {
    setDetailsEdit(true);
  }
  return (
    <EditSection title="Employee Details" edit onEditClick={onEditClick}>
      <ListItem keyword="Employee ID" value={employeeDetails?.emp_id} />
      <ListItem keyword="Department" value={employeeDetails?.dept} />
      <ListItem keyword="Employment Type" value={employeeDetails?.emp_type} />
    </EditSection>
  );
}

export default EmployeeDetails;
