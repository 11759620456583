import React from "react";

function Footer() {
  return (
    <div className="h-10 px-5 flex justify-between text-gray-500 text-sm mt-3 border-t drop-shadow relative z-10 top-5">
      <div className="flex gap-x-2 mt-2 -z-10">
        <p>Terms & Conditions</p>
        <p>|</p>
        <p>Privacy Policy</p>
        <p>|</p>
        <p>About</p>
      </div>
      <p className=" mt-2">Copyright 2022 Upkeep All rights reserved.</p>
    </div>
  );
}

export default Footer;
