import React, { useState, useEffect } from "react";
import Two from "../../assets/Two.png";
import Three from "../../assets/Three.png";
import Line from "../../assets/Line.png";
import { fetchRole } from "../../API/fetchDetails";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const DetailsBar = (props) => {
  const data = props.complete;
  // const [bankDetails, setBankDetails] = useState(true);

  // function getPersonalDetails() {
  //   fetchRole()
  //     .then(function (response) {
  //       console.log(response);
  //       setBankDetails(response?.data?.roleInfo?.banking_details);
  //       console.log("Bank Details", response?.data?.roleInfo?.banking_details);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  // useEffect(() => {
  //   getPersonalDetails();
  // }, []);

  const PercentageBar = () => {
    return (
      <div
        className={`w-[30px] h-[30px] rounded-full  ${
          data === 100 ? "bg-green-400" : "bg-white"
        } `}
      >
        <CircularProgressbar
          value={props.complete}
          text={1}
          strokeWidth={12}
          styles={buildStyles({
            pathColor: "#22c55e",
            textSize: "40px",
            textColor: `${data === 100 ? "#ffffff" : "#000000"}`,
          })}
        />
      </div>
    );
  };

  return (
    <div className="xs:hidden sm:hidden lg:flex flex-row items-center md:w-full md:h-16 bg-[#3C69DF] text-white font-bold pl-36">
      <div className="flex flex-row items-center mx-auto">
        <div className="flex flex-row relative">
          <div style={{ width: 20, height: 10 }}>
            <PercentageBar />
          </div>
          <p className="pt-1 pl-5 pr-5">Personal Details</p>
        </div>
        <img src={Line} className="w-28" alt="" />
        <div className="flex flex-row opacity-40">
          <img className="pl-5 scale-75" src={Two} alt="" />
          <p className="pt-1 pl-1 pr-5">Document Upload</p>
        </div>
        <img src={Line} className="w-28" alt="" />
        <div className="flex flex-row opacity-40">
          <img className="pl-5 scale-75" src={Three} alt="" />
          <p className="pt-1 pl-1 pr-5">Bank Details</p>
        </div>
      </div>
    </div>
  );
};

export default DetailsBar;
