import axios from "axios";
import { baseAddress } from "./config";

export function adminLogin({ email, password }) {
  console.log({ email, password });
  return axios.post(
    `${baseAddress}/hrlogin`,
    {
      email: email,
      password: password,
      accessType: "HR",
    },
    {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
  );
}

export function employeeLogin({ email, password }) {
  console.log({ email, password });
  return axios.post(
    `${baseAddress}/employee_login`,
    {
      email: email,
      password: password,
    },
    {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
  );
}

export function createAdmin({ name, email, password }) {
  return axios.post(
    `${baseAddress}/create_HR`,
    {
      name: name,
      email: email,
      password: password,
      accessType: "HR",
      org_id: "cfc557ce-dd44-4df3-b49f-389c6ede41c5",
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}

export function forgotPassword(email) {
  return axios.patch(
    `${baseAddress}/forgot_password`,
    {
      email: {
        email: email,
      },
      newPass: {
        password: "sunil",
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}
