import React, { useEffect, useState } from "react";
import { RiMoreFill, RiDeleteBin7Line } from "react-icons/ri";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { deleteRole, sortRole } from "../../../API/role";
import DeleteModal from "./DeleteModal";
import { toast } from "react-toastify";

function RolesList({ roles, setRoles }) {
  const [ShowActions, setShowActions] = useState("");
  const navigation = useNavigate();
  const [deleteModal, setDeleteModal] = useState({ state: false, id: "" });
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("Alphabetical");

  function getRoles() {
    // setLoading(true);
    sortRole(sort)
      .then(function (response) {
        // console.log(response);
        if (response?.data?.msg === "Role does not exist") {
          setRoles([]);
        } else {
          // console.log(response);
          setRoles(response?.data?.msg ?? []);
        }
        // setLoading(false);
        // setSkeleton(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // useEffect(() => {
  //   getRoles();
  // }, []);

  useEffect(() => {
    // document.body.addEventListener("click", (event) => {
    //   console.log(event);
    //   if (event?.path?.[1]?.id !== "actions") {
    //     setShowActions(false);
    //   }
    // });
    console.log({ ShowActions });
  }, []);

  const Actions = ({ id }) => {
    console.log("Actions ", id);
    return (
      <div class="absolute right-10 z-50 w-56 py-2 mt-2 overflow-hidden bg-white rounded-md shadow-xl ">
        <div
          onClick={() => navigation(`/RoleManagement/edit/${id}`)}
          className="flex  items-center block cursor-pointer px-4 py-3 capitalize transition-colors duration-200  hover:bg-gray-100"
        >
          <MdModeEdit />
          <span className="mx-1" />
          <p class=" text-sm font-medium text-gray-600  ">Edit</p>
        </div>
        <div
          onClick={() => setDeleteModal({ state: true, id: id })}
          className="flex  items-center block cursor-pointer px-4 py-3 capitalize transition-colors duration-200  hover:bg-gray-100"
        >
          <RiDeleteBin7Line />
          <span className="mx-1" />
          <p class=" text-sm font-medium text-gray-600  ">Delete</p>
        </div>
      </div>
    );
  };

  function removeRole(id) {
    deleteRole(id)
      .then(function (response) {
        console.log(response);
        getRoles();
        toast.success("Role deleted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
        setDeleteModal({ state: false, id: "" });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // console.log(deleteModal);
  const selectPageHandler = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= roles.length &&
      selectedPage !== page
    )
      setPage(selectedPage);
  };

  return (
    <>
      {roles?.map(
        ({
          role_id,
          no_of_documents,
          employee_type,
          department,
          date_created,
        }) => {
          // console.log({roles});
          return (
            <tr
              key={role_id}
              className="bg-white text-center border-b border-slate-300  text-slate-700"
            >
              <th scope="row" className="py-4 font-medium  whitespace-nowrap ">
                {department.charAt(0).toUpperCase() + department.slice(1)}
              </th>
              <td className="py-4">
                {employee_type.charAt(0).toUpperCase() + employee_type.slice(1)}
              </td>
              <td className="py-4">{date_created}</td>
              <td className="py-4 ">{no_of_documents}</td>
              <td
                onClick={() => {
                  if (ShowActions === `${role_id}${department}`) {
                    setShowActions("");
                  } else {
                    setShowActions(`${role_id}${department}`);
                  }
                }}
                id="actions"
                className="py-4"
              >
                <RiMoreFill
                  className="cursor-pointer"
                  onClick={() => {
                    // console.log({ShowActions});
                    if (ShowActions === `${role_id}${department}`) {
                      setShowActions("");
                    } else {
                      setShowActions(`${role_id}${department}`);
                    }
                    // console.log({ShowActions});
                  }}
                  size="1.5rem"
                />

                {ShowActions === `${role_id}${department}` ? (
                  <>
                    <Actions id={role_id} />
                  </>
                ) : null}
              </td>
            </tr>
          );
        }
      )}
      {deleteModal?.state && (
        <DeleteModal
          onCancel={() => {
            setDeleteModal({ state: false, id: "" });
          }}
          onConfirm={() => removeRole(deleteModal?.id)}
        />
      )}
      {roles.length > 0 && (
        <div className="p-3">
          <span
            className={page > 1 ? "visible cursor-pointer" : "invisible"}
            onClick={() => selectPageHandler(page - 1)}
          >
            ◀
          </span>
          {[...Array(Math.ceil(roles.length / 10))].map((_, i) => {
            return (
              <span
                className={`p-2 mx-1 cursor-pointer border rounded-lg ${
                  page === i + 1 ? "bg-gray-300" : ""
                }`}
                onClick={() => selectPageHandler(i + 1)}
                key={i}
              >
                {i + 1}
              </span>
            );
          })}
          <span
            className={
              page < Math.ceil(roles.length / 10)
                ? "visible cursor-pointer "
                : "invisible"
            }
            onClick={() => selectPageHandler(page + 1)}
          >
            ▶
          </span>
        </div>
      )}
    </>
  );
}

export default RolesList;
