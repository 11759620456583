import React from "react";
import { RiHome4Line, RiUser4Line, RiUserSettingsLine } from "react-icons/ri";
import logo from "../../assets/logo.jpg";
import { NavLink } from "react-router-dom";

function SideBar() {
  const ListItem = ({ title, index, icon, to }) => {
    return (
      <NavLink
        to={to}
        style={({ isActive }) =>
          isActive ? { background: "#2A3365" } : undefined
        }
        className={`w-full  flex items-center py-3 px-3  rounded-sm  text-white font-normid mt-1 cursor-pointer 
          
        }`}
      >
        {icon}
        <span className="mx-1" />
        <div className="hidden sm:block ">{title}</div>
      </NavLink>
    );
  };
  return (
    <div
      style={{ backgroundColor: "#0F163F" }}
      className="h-full w-14 py-10 sm:w-1/5 sm:p-5   flex flex-col   justify-between  "
    >
      <div className="flex justify-center">
        <ul>
          <ListItem
            title="Approval Requests"
            index={0}
            icon={<RiHome4Line size="1.5rem" />}
            to={"/ApprovalRequests"}
          />
          <ListItem
            title="Employee Management"
            index={1}
            icon={<RiUser4Line size="1.5rem" />}
            to={"/UserManagement"}
          />
          <ListItem
            title="Role Management"
            index={2}
            icon={<RiUserSettingsLine size="1.5rem" />}
            to={"/RoleManagement"}
          />
        </ul>
      </div>
      <div className="  self-center">
        <div className=" flex items-center self-center">
          <img
            className="h-10 w-10  mb-10 sm:mb-0   border rounded-full"
            src={logo}
            alt="logo"
          />
          <span className="mx-2 hidden sm:block" />
          <p className="text-white hidden sm:block">Accubits Technologies</p>
        </div>
        <p
          style={{ color: "#6272C6" }}
          className=" mt-2 text-xs text-white hidden sm:block"
        >
          Copyright 2022 Upkeep All rights reserved.{" "}
        </p>
      </div>
    </div>
  );
}

export default SideBar;
