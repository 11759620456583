import React from "react";
import EditSection from "../../common/EditSection";
import ListItem from "../../common/ListItem";

function PersonalInfo({ personalInformation }) {
  // console.log(personalInformation);
  // const personalInfo = personalInformation?.personal_info;
  // console.log({personalInfo});
  return (
    <EditSection title="Personal Information">
      {personalInformation &&
        Object.entries(personalInformation).map(([key, value]) => {
          return <ListItem keyword={key} value={value} />;
        })}
    </EditSection>
  );
}

export default PersonalInfo;
