import { logDOM } from "@testing-library/react";
import React, { useEffect } from "react";

function CheckBoxListItem({ label, checked, onChange }) {
  return (
    <div className=" flex ">
      <div className="sm:w-1/2">
        <div class="flex items-center mb-2">
          <input
            id="default-checkbox"
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              onChange(e);
            }}
            class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <span className="mx-1" />
          <label
            for="default-checkbox"
            class="py-4  sm:w-4/5   text-slate-500 text-md text-sm  font-semibold"
          >
            {label}
          </label>
        </div>
      </div>
    </div>
  );
}

export default CheckBoxListItem;
