import React, { useState, useEffect } from "react";

import UpkeepLogo from "../../assets/upkeep-logo.png";
import EmployeeLogo from "../../assets/Employee.png";
import HRLogo from "../../assets/HR-Help.png";
import PrivacyPolicyLogo from "../../assets/Privacy-Policy.png";
import UserLogo from "../../assets/user.png";

import { MdOutlineArrowDropDown } from "react-icons/md";
import { MdLogout } from "react-icons/md";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { useNavigate, useParams } from "react-router-dom";

import { fetchEmployeeDetailsOne } from "../../API/fetchDetails";

function Navbar() {
  const [ShowActions, setShowActions] = useState(false);
  const [open, setOpen] = useState(false);
  const [skeleton, setSkeleton] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState();
  const NavigateToLogin = useNavigate();

  const { userID } = useParams();

  const token = localStorage.getItem("JWT");
  //const userID = localStorage.getItem("user_id");
  const roleID = localStorage.getItem("role_id");
  const [user, setUser] = useState("");
  // function getDetails() {
  //   fetchEmployeeDetailsOne(userID)
  //     .then(function (response) {
  //       //console.log(response?.data?.result[0]?.[0]?.username);
  //       setEmployeeDetails(response?.data?.result[0]?.[0]?.username);
  //       setSkeleton(true);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  // useEffect(() => {
  //   getDetails();
  // }, []);

  useEffect(() => {
    if (!localStorage.getItem("JWT")) {
      NavigateToLogin("/");
    }
    setUser(localStorage.getItem("logged_user"));
  });

  // useEffect(() => {
  //   document.body.addEventListener("click", (event) => {
  //     if (event?.path?.[1]?.id !== "actions") {
  //       setShowActions(false);
  //     }
  //   });
  // }, []);

  const Actions = () => {
    return (
      <div className="absolute right-4 lg:right-10 z-50 w-56 py-2 mt-2 lg:mt-24 overflow-hidden bg-[#181818] rounded-md shadow-xl font-OpenSans">
        <div className="flex  items-center  cursor-pointer px-4 py-3 capitalize transition-colors duration-200">
          <MdLogout />
          <span className="mx-1" />
          <p
            class=" text-sm font-medium text-white hover:text-gray-600"
            onClick={() => {
              NavigateToLogin("/");
              localStorage.clear();
              roleID = null;
              userID = null;
            }}
          >
            Logout
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-24  top-0 left-0 bg-[#181818] text-white">
      <div className="lg:flex items-center justify-between bg-[#181818] lg:px-10 px-7">
        <a
          href="/"
          className="font-bold text-2xl cursor-pointer flex items-center font-Lato"
          onClick={() => {
            //localStorage.clear();
          }}
        >
          <span href="/" className="text-3xl mr-3 pt-2 py-4">
            <img className="py-4" src={UpkeepLogo} alt="" />
          </span>
          Upkeep
        </a>

        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-2 top-9 cursor-pointer mr-8 bg-[#181818] lg:hidden"
        >
          <ion-icon name={open ? "close" : "menu"}></ion-icon>
        </div>

        <ul
          className={` bg-[#181818] lg:flex lg:items-center lg:pb-0 pb-10 absolute lg:static lg:z-auto z-[1] left-0 w-full lg:w-auto lg:pl-0 pl-9 duration-500 ease-in ${
            open ? "top-20 " : "top-[-490px]"
          }`}
        >
          <li className="lg:ml-8 text-md lg:my-0 my-7 lg:py-6 lg:px-6 hover:bg-[#4b4b4b]">
            <a href="/personaldetails" className="duration-500">
              <img className="h-6 mr-2 inline" src={EmployeeLogo} alt="" />
              Employee details
            </a>
          </li>
          <li className="lg:ml-8 text-md lg:my-0 my-7 lg:py-6 lg:px-6 hover:bg-[#4b4b4b]">
            <a href="/personaldetails" className="duration-500">
              <img className="h-6 mr-2 inline" src={HRLogo} alt="" />
              HR Help & Support
            </a>
          </li>
          <li className="lg:ml-8 text-md lg:my-0 my-7 lg:py-6 lg:px-6 hover:bg-[#4b4b4b]">
            <a href="/personaldetails" className="duration-500">
              <img className="h-6 mr-2 inline" src={PrivacyPolicyLogo} alt="" />
              Privacy Policy
            </a>
          </li>
        </ul>
        <span
          id="actions"
          className={` bg-[#181818] flex items-center lg:pb-0 pb-10 absolute lg:static lg:z-auto z-[1] left-0 w-full lg:w-auto lg:pl-0 pl-9 duration-500 ease-in ${
            open ? "top-72" : "top-[-490px]"
          }`}
        >
          <img className="h-10 inline mr-4" src={UserLogo} alt="" />
          {/* {!skeleton && (
            <Skeleton
              count={1}
              style={{ width: 50, backgroundColor: "black" }}
            />
          )} */}
          {/* {skeleton && <p>{`${employeeDetails}`}</p>} */}
          <p>{user}</p>
          <MdOutlineArrowDropDown
            className="cursor-pointer float-right ml-4"
            onClick={() => setShowActions(!ShowActions)}
            size="1.5rem"
          />
          {ShowActions ? (
            <>
              <Actions />
            </>
          ) : null}
        </span>
      </div>
    </div>
  );
}

export default Navbar;
