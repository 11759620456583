import React from 'react';
import One from '../../assets/One.png';
import Two from '../../assets/Two.png';
import ThreeProgress from '../../assets/ThreeProgress.png';
import Line from '../../assets/Line.png';
import OneComplete from '../../assets/OneComplete.png';
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const DetailsBarThree = (props) => {
  const data=props.complete
  const PercentageBar = () => {
    return (
      <div className={`w-[30px] h-[30px] rounded-full  ${ data ==100 ? "bg-green-400":"bg-white"} `}>
        <CircularProgressbar 
         value={props.complete}
          text={3} 
          strokeWidth={12}
          styles={buildStyles({
            pathColor:'#22c55e',
            textSize:'40px',
            textColor:`${data==100? '#ffffff':'#000000'}`
            })}/>
      </div>
    );
  };
  return (
    <div className='xs:hidden sm:hidden lg:flex flex-row items-center md:w-full md:h-16 bg-[#3C69DF] text-white font-bold pl-36'>
      <div className='flex flex-row items-center mx-auto'>
        <div className='flex flex-row relative opacity-40'>
          <div className='w-[30px] h-[30px] rounded-full bg-green-400'>
            <CircularProgressbar 
              value={100}
              text={1} 
              strokeWidth={12}
              styles={buildStyles({
                pathColor:'#22c55e',
                textSize:'40px',
                textColor:'#ffffff',
                
              })}/>
          </div>
          <p className='pt-1 pl-1 pr-5'>Personal Details</p>
        </div>
        <img src={Line} className="w-28" alt='' />
        <div className='flex flex-row relative opacity-40'>
          <div className='w-[30px] h-[30px] rounded-full bg-green-400 '>
              <CircularProgressbar 
                value={100}
                text={2} 
                strokeWidth={12}
                styles={buildStyles({
                  pathColor:'#22c55e',
                  textSize:'40px',
                  textColor:'#ffffff',
                  
                })}/>
            </div>
          <p className='pt-1 pl-1 pr-5'>Document Upload</p>
        </div>
        <img src={Line} className="w-28" alt='' />
        <div className='flex flex-row'>
          <div style={{ width: 20, height: 10 }}>
            <PercentageBar/>
          </div>
          <p className='pt-1 pl-5 pr-5'>Bank Details</p>
        </div>
      </div>
    </div>
  )
}

export default DetailsBarThree