import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import LineLogo from "../../../assets/LineLogo.png";
import { GoVerified } from "react-icons/go";
import { verifyEmployee } from "../../../API/employee";

function ReportSummary({ onClickHandler }) {
  const [personalInformation, setPersonalInformation] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigateBack = useNavigate();

  const { id } = useParams();

  function getDetails() {
    setLoading(true);
    verifyEmployee(id)
      .then(function (response) {
        console.log(response);
        setPersonalInformation(response);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }


  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        height: "100vh",
        width: "100%",
        background: " rgba(0, 0, 0, 0.5)",
        zIndex: "1218",
      }}
    >
      <div className="mx-auto sm:px-10 top-10 mt-20 md:container md:mx-auto  justify-between items-center">
        <div
          style={{
            width: window.innerWidth > 600 ? "500px" : window.innerWidth - 20,
            // height: window.innerHeight > 700 ? "200px" : "200px",

            position: "fixed",
            background: "white",
            left: "0",

            right: "0",
            margin: "auto",
            borderRadius: "10px",
          }}
          className="shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] p-2"
        >
          {/* <IoIosClose
            className="float-right cursor-pointer"
            size={25}
            onClick={onClickHandler}
          /> */}
          <div style={{ marginLeft: "35px" }}>
            <div>
              <h6 className="text-sm sm:text-lg font-bold mb-3 pt-1">
                Report Summary
              </h6>
            </div>

            <div className="mb-5">
              <img src={LineLogo} alt="" />
            </div>
            <div>
              <div className="flex items-center">
                <GoVerified color="green" />
                <p className="mx-2">All document are verfied and confirmed</p>
              </div>
              <div className="my-4 flex justify-end  ">
                <div className="self-end">
                  <button
                    onClick={onClickHandler}
                    className="px-4 py-1 text-bold rounded-md text-blue-800 border-2 border-buttonColor hover:bg-slate-100 "
                  >
                    Cancel
                  </button>
                  <span className="mx-2" />
                  <button
                    onClick={() => {
                      navigateBack("/ApprovalRequests");
                    }}
                    className="px-4 py-1   border-2 border-buttonColor rounded-lg bg-[#3C69DF] hover:bg-[#284ba3] text-white text-bold"
                  >
                    Verify Employee
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportSummary;
