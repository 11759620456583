import axios from "axios";
import { baseAddress } from "./config";

const HRtoken = localStorage.getItem("JWT");
const HRid = localStorage.getItem("HRid");

export function fetchEmpType(dept) {
  // console.log(dept,baseAddress);
  // console.log(localStorage.getItem("JWT"));
  return axios.post(
    `${baseAddress}/fetch_employee_type`,
    {
      dept: dept,
    },
    {
      headers: {
        JWT: localStorage.getItem("JWT"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
}
