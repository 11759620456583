import React, { useState } from 'react';
import DetailsBarThree from "./DetailsBarThree";
import CompletionBarThree from './CompletionBarThree';
import InfoThree from './InfoThree';
import Navbar from '../PageTwo/Navbar';

const PageThree = () => {
 // const [menuDrop, setMenuDrop] = useState(false);
 const [complete, setComplete] = useState(0);
  return (
    <>
      <Navbar />
      <DetailsBarThree complete={complete} />
      <CompletionBarThree complete={complete}/>
      <InfoThree complete={complete} setComplete={setComplete}/>
    </>
  )
} 

export default PageThree