import React from "react";
import CheckBoxListItem from "../../common/CheckBoxListItem";
import EditSection from "../../common/EditSection";

function BankAccDetails({ bankDetails, setBankDetails }) {
  // console.log(bankDetails);
  return (
    <EditSection title="Bank Account Details">
      {[{ text: "Required", name: "banking_details" }].map(({ text, name }) => (
        <CheckBoxListItem
          label={text}
          checked={bankDetails[name]}
          onChange={(e) => {
            setBankDetails({ ...bankDetails, [name]: e.target.checked });
          }}
        />
      ))}
    </EditSection>
  );
}

export default BankAccDetails;
