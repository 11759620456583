import React, { useState } from "react";
import Info from "./Info";
import DetailsBar from "./DetailsBar";
import CompletionBar from "./CompletionBar";
import Navbar from "./Navbar";

const Page = () => {
  const [complete, setComplete] = useState(0);
  return (
    <>
      <Navbar />
      <DetailsBar complete={complete} />
      <CompletionBar complete={complete} />
      <Info complete={complete} setComplete={setComplete} />
    </>
  );
};

export default Page;
