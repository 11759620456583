import React, { useEffect, useState } from "react";
import Page from "../common/Page";
import Skeleton from "react-loading-skeleton";
import ExportPopup from "../common/ExportPopup";
import {
  exportEmployee,
  sortEmployee,
  fetchAllEmployees,
  filterEmployee,
  filterEmployeeType,
  filterBy,
} from "../../API/employee";
import FilteredSearchBar from "../common/FilteredSearchBar";

import Table from "../common/Table";
import CreateEmployee from "../EmployeeManagement/CreateEmployee";
import EmployeeList from "./elements/EmployeeList";

const UserManagement = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState({});
  const [form, setForm] = useState(false);
  const [skeleton, setSkeleton] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState({ dept: [], empType: [] });
  const [loading, setLoading] = useState(false);
  const [postPerPage] = useState(10);
  const [sort, setSort] = useState("Alphabetical");

  function getAllEmployees() {
    setLoading(true);
    fetchAllEmployees()
      .then(function (response) {
        // console.log(
        //   "get all employee details employees",
        //   response?.data?.result
        // );
        console.log(response);
        setEmployees(response?.data?.result);
        setLoading(false);
        setSkeleton(true);
        setPageCount(2);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  }

  //Sorting record
  useEffect(() => {
    console.log("sort");
    if (sort) {
      getSortedEmployee();
    }
  }, [sort]);

  useEffect(() => {
    console.log(selectedEmployeeIds);
  }, []);

  useEffect(() => {
    if (filter.empType.length === 0 && filter.dept.length === 0) {
      console.log(filter.empType.length);
      getSortedEmployee();
    } else {
      console.log("filteremptype");
      Filter_By(filter.dept, filter.empType);
    }
    // }
  }, [filter]);

  function Filter_By(dept, emptype) {
    setLoading(true);
    filterBy(dept, emptype)
      .then(function (response) {
        console.log(response);
        if (response?.data?.msg === "Employee doesnot exist") {
          setEmployees([]);
        } else {
          console.log(response);
          setEmployees(response?.data?.result ?? []);
          setLoading(false);
          setSkeleton(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getSortedEmployee() {
    // console.log(sort);
    setLoading(true);
    sortEmployee(sort)
      .then(function (response) {
        // console.log(response);
        if (response?.data?.msg === "Employee doesnot exist") {
          setEmployees([]);
        } else {
          // console.log(response);
          setEmployees(response?.data?.msg ?? []);
        }
        // console.log(response?.data?.msg??[]);
        setLoading(false);
        setSkeleton(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getFilteredRoles() {
    console.log(filter.dept);
    setLoading(true);
    filterEmployee(filter.dept)
      .then(function (response) {
        console.log(response);
        if (response?.data?.msg === "Role does not exist") {
        } else {
          setEmployees(response?.data?.result);
        }

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getFilteredRolesEmpType() {
    console.log(filter.empType);
    setLoading(true);
    filterEmployeeType(filter.empType)
      .then(function (response) {
        console.log(response);
        if (response?.data?.msg === "Role does not exist") {
        } else {
          setEmployees(response?.data?.result);
        }

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const ExportPopup = () => {
    <ExportPopup />;
  };

  // Export Employee data
  function exportValue() {
    console.log(selectedEmployeeIds);
    if (Object.keys(selectedEmployeeIds).length == 0) {
      console.log(selectedEmployeeIds);
      alert("Please select employees");
      return;
    }
    setLoading(true);
    exportEmployee(selectedEmployeeIds)
      .then(function (response) {
        console.log(response);
        setLoading(false);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display:none";
        let filename = "export.csv";
        const blob = new Blob([response?.data], { type: "octet/stream" }),
          url = window.URL.createObjectURL(blob);
        // console.log(url);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getSearchResult(value) {
    if (!value) {
      getSortedEmployee();
    } else {
      setEmployees(() => {
        // console.log(employees);
        return employees?.filter((emp) => {
          // console.log(emp);
          return emp?.username?.includes(value) || emp?.emp_id?.includes(value);
        });
      });
    }
  }

  return (
    <>
      <Page>
        <div className="my-1  sm:my-3 sm:flex sm:justify-between">
          <p class="text-lg font-semibold font-OpenSans">Employees</p>
          <div className=" flex justify-between items-center p-2 sm:block sm:p-0 ">
            <button
              className="px-8 py-1 text-bold rounded-md text-blue-800 border-2 border-buttonColor hover:bg-slate-100 "
              onClick={() => {
                ExportPopup();
                exportValue();
              }}
            >
              {loading ? (
                <svg
                  class="inline mr-2 w-8 h-8  animate-spin text-white fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Export"
              )}
            </button>
            <span className="mx-2" />
            <button
              onClick={() => setForm(true)}
              className="px-8 py-1   border-2 border-buttonColor rounded-lg bg-[#3C69DF] hover:bg-[#284ba3] text-white text-bold"
            >
              {window.innerWidth > 500 ? "+ Create Employee Account" : "Create"}
            </button>

            {form ? (
              <CreateEmployee onClickHandler={() => setForm(false)} />
            ) : null}
          </div>
        </div>
        <FilteredSearchBar
          sort={sort}
          setSort={setSort}
          filter={filter}
          setFilter={setFilter}
          title="Enter Name or Employee Id"
          onSearch={(value) => {
            getSearchResult(value);
          }}
          searchBar
          filterDepartment
          filterEmpType
        />
        <Table
          headTitles={[
            "checkbox",
            "Full Name",
            "Employee ID",
            "DOJ",
            "Department",
            "Employee Type",
            "Email",
            "Employee Status",
          ]}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
        >
          {!skeleton && (
            <Skeleton
              count={10}
              style={{
                display: "grid",
                height: 45,
                width: 1500,
                marginTop: "1rem",
              }}
            />
          )}
          {skeleton && (
            <EmployeeList
              employees={employees}
              setEmployees={setEmployees}
              selectedEmployeeIds={selectedEmployeeIds}
              setSelectedEmployeeIds={setSelectedEmployeeIds}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
            />
          )}
        </Table>
      </Page>
    </>
  );
};
export default UserManagement;
