import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "../src/upkeep_employee/components/PageOne/Login";
import PageTwo from "../src/upkeep_employee/components/PageThree/PageTwo";
import PageThree from "../src/upkeep_employee/components/PageFour/PageThree";
import Page from "../src/upkeep_employee/components/PageTwo/Page";
import PageConfeti from "../src/upkeep_employee/components/PageFive/PageConfeti";

import AdminLogin from "../src/upkeep_hr/components/admin/AdminLogin";
import ForgotPass from "../src/upkeep_hr/components/admin/forgotPass";
import EditEmployee from "../src/upkeep_hr/components/EmployeeManagement/EditEmployee";
import UserManagement from "../src/upkeep_hr/components/EmployeeManagement/userManagement";
import ApprovalRequests from "../src/upkeep_hr/components/RequestManagement/ApprovalRequests";
import EditApprovalRequests from "../src/upkeep_hr/components/RequestManagement/EditApprovalRequests";
import CreateRole from "../src/upkeep_hr/components/RoleManagement/CreateRole";
import EditRole from "../src/upkeep_hr/components/RoleManagement/EditRole";
import RoleManagement from "../src/upkeep_hr/components/RoleManagement/RoleManagement";
import CreateAdmin from "../src/upkeep_hr/components/admin/CreateAdmin";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Router>
        <Routes>


          <Route exact path="/" element={<Login />} />
          <Route exact path="/personaldetails" element={<Page />} />
          <Route exact path="/morepersonaldetails" element={<PageTwo />} />
          <Route exact path="/bankdetails" element={<PageThree />} />
          <Route exact path="/congratulations" element={<PageConfeti />} />


          <Route exact path="/AdminLogin" element={<AdminLogin />} />
          <Route exact path="/CreateAdmin" element={<CreateAdmin />} />
          <Route exact path="/ForgotPass" element={<ForgotPass />} />
          <Route exact path="/UserManagement" element={<UserManagement />} />
          <Route
            exact
            path="/UserManagement/edit/:id"
            element={<EditEmployee />}
          />
          <Route exact path="/RoleManagement" element={<RoleManagement />} />
          <Route exact path="/RoleManagement/edit/:id" element={<EditRole />} />
          <Route
            exact
            path="/RoleManagement/add/:id"
            element={<CreateRole />}
          />
          <Route
            exact
            path="/ApprovalRequests"
            element={<ApprovalRequests />}
          />
          <Route
            exact
            path="/ApprovalRequests/view/:id"
            element={<EditApprovalRequests />}
          />


        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
