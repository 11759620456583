import React, { useEffect, useState } from "react";
import UpkeepLogo from "../../assets/upkeep-logo.png";
import UserLogo from "../../assets/User.png";
import Vector from "../../assets/Vector.png";
import { FiHeadphones } from "react-icons/fi";
import { RiMenu5Line, RiMenuFill, RiNotification2Line } from "react-icons/ri";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function NavBar({ setShowSideBar }) {
  const name = localStorage.getItem("name");
  // console.log(name);
  const [ShowActions, setShowActions] = useState(false);
  const NavigateToHRLogin = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("JWT")) {
      NavigateToHRLogin("/AdminLogin");
    }
  });

  const Actions = () => {
    return (
      <div className="absolute right-4 lg:right-10 z-50 w-56 py-2 mt-2 lg:mt-24 overflow-hidden bg-white rounded-md shadow-xl font-OpenSans">
        <div className="flex  items-center  cursor-pointer px-4 py-3 capitalize transition-colors duration-200">
          <MdLogout className="hover:text-gray-500" />
          <span className="mx-1" />
          <p
            class=" text-sm font-medium text-black hover:text-gray-500"
            onClick={() => {
              NavigateToHRLogin("/AdminLogin");
            }}
          >
            Logout
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-center w-auto px-1 sm:px-2 py-4 md:left-0 bg-white justify-between">
      <div className="flex flex-row scale-110 sm:mx-10">
        <div
          onClick={() => setShowSideBar((prev) => !prev)}
          className="sm:w-auto sm:hidden flex items-center pl-2 sm:px-10 rounded-sm  text-slate-700 font-normid mt-1 cursor-pointer"
        >
          <RiMenu5Line size="1.5rem" />
        </div>
        <img className="scale-75" src={UpkeepLogo} alt="" />
        <h1 className="font-bold font-Lato text-xl pt-2">Upkeep</h1>
      </div>
      <div className="flex items-center ">
        <FiHeadphones
          className="text-neutral-800 hover:text-black cursor-pointer"
          size="1.2rem"
        />
        <span className="mx-2 sm:mx-5" />
        <RiNotification2Line
          className="text-neutral-800 hover:text-black cursor-pointer"
          size="1.2rem"
        />
        <span className="mx-2 sm:mx-4" />
        <div className=" flex border-2 items-center px-2 -mx-4 py-1 rounded-xl">
          <img className="h-8" src={UserLogo} alt="userPhoto" />
          <span className="mx-1" />
          <p className="font-normal text-md hidden sm:block ">{name}</p>
          <span className="mx-2" />
          <MdOutlineArrowDropDown
            className="cursor-pointer float-right ml-4 mt-2"
            onClick={() => setShowActions(!ShowActions)}
            size="1.5rem"
          />
          {ShowActions ? (
            <>
              <Actions />
            </>
          ) : null}
        </div>
        <span className="mx-1 sm:mx-4" />
      </div>
    </div>
  );
}

export default NavBar;
