import React from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";

function ListItem({ keyword, value, edit, onEditClick }) {
  let tag = keyword.replace("_", " ");
  return (
    <div className="border-b flex ">
      <div className="w-1/2">
        <p className="py-4 w-4/5   text-[#808080] text-md text-sm  font-normal">
          {tag.charAt(0).toUpperCase() + tag.slice(1)}
        </p>
      </div>
      <p className="py-4 w-1/2 flex  text-[#343434] text-sm items-center  font-medium">
        {value} <span className="mx-1" />
        {edit && (
          <MdOutlineModeEditOutline
            onClick={onEditClick}
            className="cursor-pointer"
            size="1.2rem"
          />
        )}
      </p>
    </div>
  );
}

export default ListItem;
