import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EditSection from "../../common/EditSection";
import Table from "../../common/Table";
import { fetchEmployeeDetails } from "../../../API/employee";

function DocumentSection({ setAddDocument }) {
  const [documentDetails, setDocumentDetails] = useState([]);
  const [loading, setLoading] = useState();
  const { id } = useParams();

  function getDetails() {
    setLoading(true);
    fetchEmployeeDetails(id)
      .then(function (response) {
        console.log(response?.data?.result[2]?.[0]?.documents);
        setDocumentDetails(response?.data?.result[2]?.[0]?.documents);
        // console.log(documentDetails);

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    getDetails();
  }, []);

  const ListItem = ({ data }) => {
    return (
      <tr className="bg-white font-OpenSans text-center border-b border-slate-300 text-[#343434]">
        <td className="py-4">{data.index + 1}</td>
        <td className="py-4">{data.documentName}</td>
        <td className="py-4 text-blue-500">
          <a href={data.docLink} target="_blank">
            View Document
          </a>
        </td>
        <td className="text-blue-500 ">
          <a href="#">Download</a>
        </td>
      </tr>
    );
  };
  console.log(documentDetails);
  return (
    <EditSection title="Documents" secondary="Download all">
      <Table headTitles={["#", "Name", "Document", "Download"]}>
        {documentDetails &&
          Object.entries(documentDetails).map((name, index) => {
            console.log(name, index);
            if (name[1] != 0 && name[1] != 1 && name[1] != 2) {
              return (
                <ListItem
                  data={{
                    documentName: name[0],
                    index: index,
                    docLink: name[1],
                  }}
                />
              );
            }
          })}
      </Table>
      <p
        onClick={() => setAddDocument(true)}
        className="text-[#0496FF] p-2 font-normal cursor-pointer "
      >
        + Add document
      </p>
    </EditSection>
  );
}

export default DocumentSection;
