import React from "react";

const CompletionBarThree = ({ complete }) => {
  const ProgressBar = ({}) => {
    return (
      <div className="h-2 w-full bg-gray-300 rounded overflow-hidden">
        <div
          style={{ width: `${complete}%` }}
          className={`h-full ${complete < 70 ? "bg-red-600" : "bg-green-600"}`}
        ></div>
      </div>
    );
  };
  return (
    <div>
      <nav className="relative bg-[#F5F5F5] text-black text-bold h-20 items-center">
        <div className="flex flex-row lg:grid lg:grid-cols-2">
          <div className="flex flex-row">
            <h1 className="ml-4 lg:ml-48 font-bold mt-4 lg:mt-4 font-OpenSans items-center text-lg lg:text-3xl">
              Bank Details
            </h1>
            <h3 className="text-red-400 ml-4 font-bold mt-4 lg:mt-7 text-xs font-OpenSans items-center">
              (*All the fields are required)
            </h3>
          </div>
          <div className="mt-7 lg:mt-8 w-1/4 ml-12 lg:w-3/4 lg:flex lg:flex-row">
            <ProgressBar />
            <p className="text-sm lg:text-lg -mt-2 w-full ml-8 font-semibold hidden lg:inline">
              {` ${Math.ceil(complete)} % complete`}
            </p>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default CompletionBarThree;
