import React from "react";
import CheckBoxListItem from "../../common/CheckBoxListItem";
import EditSection from "../../common/EditSection";

function DocumentSection({
  documents,
  setDocuments,
  newDocumentsFields,
  setNewDocumentsFields,
}) {
  return (
    <EditSection title="Documents" subtitle="Select from the list below">
      {[
        { text: "Signed offer letter", name: "offerLetter" },
        { text: "Aadhar Card", name: "aadharCard" },
        { text: "Photograph", name: "photograph" },
        { text: "Pan Card", name: "panCard" },
        { text: "Passport", name: "passport" },
        { text: "Drivers License", name: "drivingLicense" },
        { text: "10th grade certificate", name: "sscCertificate" },
        { text: "12th grade certificate", name: "hscCertificate" },
        { text: "Resume", name: "resume" },
        { text: "Experience Letter", name: "experienceLetter" },
        { text: "Relieving Letter", name: "relievingLetter" },
        { text: "Payslips (3 months)", name: "payslip" },
        { text: "Passbook/Cancelled cheque", name: "passbook" },
        { text: "Form 16", name: "form16" },
        { text: "NDA", name: "nda" },
      ].map(({ text, name }) => {
        // console.log(text,name);
        return (
          <CheckBoxListItem
            label={text}
            checked={documents?.[text]}
            onChange={(e) => {
              setDocuments({ ...documents, [name]: e.target.checked });
            }}
          />
        );
      })}
      {newDocumentsFields?.map(({ text, name }) => {
        return (
          <CheckBoxListItem
            label={text}
            checked={documents?.[name]}
            onChange={(e) => {
              setDocuments({ ...documents, [text]: e.target.checked });
            }}
          />
        );
      })}
    </EditSection>
  );
}

export default DocumentSection;
