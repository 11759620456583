import React, { useState, useEffect } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import { fetchRole } from "../../API/fetchDetails";
import Footer from "../common/Footer";

import LineTwo from "../../assets/Page-two.png";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";

const Info = ({ complete, setComplete }) => {
  const navigateDetails = useNavigate();

  const [loading, setLoading] = useState(false);
  //--------------Checkbox-----------------------
  const [checkBox, setCheckBox] = useState(false);

  const [employeePersonalDetails, setEmployeePersonalDetails] = useState({});
  const [skeleton, setSkeleton] = useState(null);
  const [ShowActions, setShowActions] = useState(false);
  const [ShowMedicalActions, setShowMedicalActions] = useState(false);
  const [ShowAllergyActions, setShowAllergyActions] = useState(false);

  const [collectPersonalInfo, setCollectPersonalInfo] = useState({});
  const [existvalue, setExistvalue] = useState({});

  const token = localStorage.getItem("JWT");
  const userID = localStorage.getItem("user_id");
  const roleID = localStorage.getItem("role_id");
  const location = useLocation();
  const onboarding_status = location.state.status;

  function getPersonalDetails() {
    fetchRole()
      .then(function (response) {
        localStorage.setItem("role_id", response?.data?.roleInfo?.role_id);
        console.log(
          "get Employee ",
          response?.data?.roleInfo?.personal_details
        );
        setEmployeePersonalDetails(response?.data?.roleInfo?.personal_details);
        setSkeleton(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log(location.state);
    if (location.state !== "") {
      setExistvalue(location.state.personal_details);
      setCollectPersonalInfo(location.state.personal_details);
    }
    if (onboarding_status === "Completed") {
      setComplete(100);
    }
    getPersonalDetails();
  }, []);

  //---------------PERSONAL DETAILS Collecting---------------------
  const collectData = async (userData) => {
    return axios.post(
      "https://upkeep-be-uat.devtomaster.com/add_personal_details",
      {
        user_id: JSON.stringify(userID),
        data: JSON.stringify(userData),
      },
      {
        headers: {
          "Content-Type": "application/json",
          JWT: token,
        },
      }
    );
  };
  // "{\"first_name\":\"Rajesh\",\"last_name\":\"Mishra\",\"doj\":\"10-10-2020\",\"alt_email\":\"rajesh@gmail.com\",\"blood_group\":\"o+ve\"}"

  function progressbar() {
    let input_field = document
      .querySelector("#input")
      .querySelectorAll("#inputText");
    let count_field = 0;
    //count no.of filled field
    for (let i = 0; i < input_field.length; i++) {
      if (input_field[i].value !== "") count_field++;
    }
    let percentage = (100 / input_field.length) * count_field;
    if (percentage > 100) percentage = 100;
    setComplete(percentage); //add percentage in progressbar
  }

  const collectPersonalDetails = (e) => {
    const { name, value } = e.target;
    progressbar();
    setCollectPersonalInfo(() => {
      return {
        ...collectPersonalInfo,
        [name]: value,
      };
    });

    console.log(collectPersonalInfo);
  };

  // Window scroll
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Organ
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (event?.path?.[1]?.id === "actions") {
        setShowActions(false);
      }
    });
  }, []);

  //Medical conditions
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (event?.path?.[1]?.id === "actions") {
        setShowMedicalActions(false);
      }
    });
  }, []);

  //Allergy
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (event?.path?.[1]?.id === "actions") {
        setShowAllergyActions(false);
      }
    });
  }, []);

  //For progress bar percentage
  const progressPercentage = (e) => {
    setComplete(complete + 10);
  };

  /* Organ donor drop down*/

  const OrganActions = () => {
    return (
      <div className="absolute font-OpenSans">
        <div className="flex flex-col w-auto mx-auto lg:-mt-4 lg:-ml-56">
          <label className="pb-2 text-[#656565] mt-8">
            Registration number
          </label>
          <input
            className="text-xs w-64 border-2 rounded-lg p-2.5 border-[#AEAEAE] hover:border-[#3C69DF] lg:w-80"
            type="email"
            placeholder="Alternative email"
          />
        </div>
      </div>
    );
  };

  /* Medical conditions dropdown */

  const MedicalActions = () => {
    return (
      <div className="absolute font-OpenSans">
        <div className="flex flex-col w-auto mx-auto lg:-mt-4 lg:-ml-56">
          <label className="pb-2 text-[#656565] mt-8">
            Pre existing medical conditions
          </label>
          <input
            className="text-xs w-64 border-2 rounded-lg p-2.5 border-[#AEAEAE] hover:border-[#3C69DF] lg:w-80 "
            type="text"
            placeholder="Pre existing medical conditions"
          />
        </div>
      </div>
    );
  };

  /* Allergy dropdown */

  const AllergyActions = () => {
    return (
      <div className="absolute font-OpenSans">
        <div className="flex flex-col w-auto mx-auto lg:-mt-4 lg:-ml-56">
          <label className="pb-2 text-[#656565] mt-8">Allergents</label>
          <input
            className="text-xs w-64 border-2 rounded-lg p-2.5 border-[#AEAEAE] hover:border-[#3C69DF] lg:w-80 "
            type="text"
            placeholder="Please mention what you are allergic to"
          />
        </div>
      </div>
    );
  };

  const Submit = () => {
    let inputField = document.querySelectorAll("input");

    for (let i = 0; i < inputField.length; i++) {
      if (inputField[i].value == "") {
        toast("Please fill the Fields", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
        return;
      }
    }

    //setLoading(true);
    collectData(collectPersonalInfo);
    if (checkBox === false) {
      //setLoading(false);
      toast("Please fill the checkbox", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    } else if (complete < 100) {
      toast("Please fill all the fields", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    } else {
      //setLoading(true);
      navigateDetails("/morepersonaldetails", {
        state: {
          status: onboarding_status,
          document: location.state.documents,
          bankdetails: location.state.bank_details,
        },
      });
    }
  };

  const saveChanges = () => {
    if (complete < 100) {
      toast("Please fill all the fields", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    setLoading(true);
    collectData(collectPersonalInfo)
      .then(function (response) {
        console.log("response ", response);
        setLoading(false);
        if (response?.data && checkBox === true) {
          toast(response?.data.msg, {
            type: "success",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
          console.log("success");
        } else if (checkBox === false) {
          toast("Please fill the checkbox", {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else {
          toast("Try again", {
            type: "info",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast("Something went wrong!", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
        console.log(error);
      });
  };

  return (
    <div className="font-OpenSans">
      {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}

      <div className="grid lg:grid-cols-2 md:grid-cols-1">
        {/*Section 1 -- Col 1 */}

        <div className="grid lg:grid-cols-1 lg:row-span-6 lg:mt-16 lg:ml-48 md:ml-28 sm:ml-5 md:mt-10 xs:ml-5">
          <div>
            <h1 className="text-black font-bold text-2xl">Personal info</h1>
            <h2 className="hidden text-[#A1A1A1] lg:flex">
              Provide your personal info
            </h2>
          </div>
        </div>
        <div
          className="grid lg:grid-cols-2 md:grid-cols-2 mt-5 md:mt-16 lg:ml-4 md:ml-24"
          id="input"
        >
          {!skeleton && (
            <Skeleton
              count={5}
              style={{
                display: "grid",
                height: 60,
                width: 400,
                marginBottom: "3rem",
              }}
            />
          )}
          {skeleton &&
            Object.keys(employeePersonalDetails).map((key) => {
              //console.log(key);
              return (
                <div className="p-5">
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border-2 border-[#bbbbbb] hover:border-[#3C69DF] 
                  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-50
                  0 block w-full  p-2.5 dark:border-gray-600 dark:placeholder-gray-400 
                  dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:bg-white"
                    placeholder={key.charAt(0).toUpperCase() + key.slice(1)}
                    id="inputText"
                    name={key}
                    required=""
                    disabled={onboarding_status === "Completed" ? true : false}
                    defaultValue={existvalue[key]}
                    onChange={collectPersonalDetails}
                  />
                </div>
              );
            })}
        </div>
      </div>

      {/* Line in between */}

      <img
        className="scale-50 lg:mt-28 lg:flex lg:ml-44"
        src={LineTwo}
        alt=""
      />

      {/* FOOTER */}

      {onboarding_status !== "Completed" ? (
        <div className="flex flex-col px-auto lg:pl-24 items-center">
          <div className="hidden lg:ml-28 lg:mt-5">
            <img className="" src={LineTwo} alt="" />
          </div>

          <div className="flex flex-row mt-2 lg:ml-16 lg:pr-5 lg:px-5 md:pl-24 xs:px-5">
            <input
              className="scale-105 lg:ml-4 lg:scale-125"
              type="checkbox"
              onClick={() => {
                setCheckBox(true);
              }}
            />
            <label className="mt-8 lg:mt-1 text-xs text-[#5F5F5F] pl-2 lg:text-base lg:w-full  ">
              I certify that the above facts are true to the best of my
              knowledge and I understand that I subject myself to disciplinary
              action in the event that the above facts are found to be
              falsified.
            </label>
          </div>
          <div className="flex flex-row w-full justify-end mr-10 gap-x-10">
             <button className="mt-4 w-72 h-12 ml-10 border-2 rounded-lg bg-[#3B69DF] hover:bg-[#1c377e] text-white lg:mt-4 lg:ml-6 lg:h-12 lg:w-96"
              onClick={() => {
                saveChanges(true);
                Submit();
              }}
            >
              {loading ? (
                <svg
                  class="inline mr-2 w-8 h-8  animate-spin text-white fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save and Continue "
              )}
            </button>                    </div>
        </div>
      ) : (
        <div className="flex flex-row justify-end">
          <button
            className="mt-4 h-12 border-2 rounded-lg bg-[#3B69DF] 
            hover:bg-[#1c377e] text-white lg:mt-4 xs:w-3/12 w-3/12"
            onClick={() => {
              navigateDetails("/morepersonaldetails", {
                state: {
                  status: onboarding_status,
                  document: location.state.documents,
                  bankdetails: location.state.bank_details,
                },
              });
            }}
          >
            Next
          </button>
        </div>
      )}
      <ToastContainer />

      <Footer />

      {/* Main Div closing tag */}
    </div>
  );
};
export default Info;
