import axios from "axios";
import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import LineTwo from "../../assets/Page-two.png";
import pdfIcon from "../../assets/pdfIcon.png";
import { fetchRole } from "../../API/fetchDetails";

import { AiFillEye } from "react-icons/ai";

import Footer from "../common/Footer";
import { uploadFile } from "react-s3";
import { ToastContainer, toast } from "react-toastify";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";

window.Buffer = window.Buffer || require("buffer").Buffer;

const InfoTwo = ({ complete, setComplete }) => {
  const navigateDetails = useNavigate();
  const [skeleton, setSkeleton] = useState(null);
  const [document, setDocument] = useState({ offerLetter: "" });
  const [loading, setLoading] = useState(false);

  // ---------------Current Location-----------------
  const location = useLocation();

  //--------------Checkbox-----------------------
  const [checkBox, setCheckBox] = useState(false);

  //------------For fetch_role API-----------------------------
  const [employeeDocument, setEmployeeDocuments] = useState([]);

  //----------------For collecting Documents using MAP-----------------------
  const [collectEmployeeDocument, setCollectEmployeeDocument] = useState([]);

  //----------------For collecting Documents and storing to API--------------------
  const [collectDocumentsState, setCollectDocumentState] = useState({});
  const [noOfDocuments, setNoOfDocuments] = useState();

  const token = localStorage.getItem("JWT");
  const userID = localStorage.getItem("user_id");

  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState("");
  const [existvalue, setExistvalue] = useState({});
  const onboarding_status = location.state.status;

  //-------For fetching Document requried---------------------------
  function getPersonalDetails() {
    fetchRole()
      .then(function (response) {
        setEmployeeDocuments(response?.data?.roleInfo?.documents);
        setNoOfDocuments(response?.data?.roleInfo?.no_of_documents);
        setSkeleton(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    // console.log(location.state.document);
    // console.log(location.state.bankdetails);
    console.log(onboarding_status);

    setExistvalue(location.state.document);
    if (onboarding_status === "Completed") {
      setComplete(100);
    }
    console.log(location.state.document);
    console.log(existvalue["offerLetter"]);
    getPersonalDetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //-----------------------Config for AWS--------------------------------------
  const config = {
    bucketName: "accudao-dev",
    region: "ap-south-1",
    accessKeyId: "AKIAWBYPBBMBPIHEEB44",
    secretAccessKey: "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",
    s3Url: "https://accudao-dev.s3.ap-south-1.amazonaws.com/" /* optional */,
  };
  //......................................................................................................

  //   const setUniqueFileName=(selectedFile)=>{
  //     let test=selectedFile.name
  //     test=test.replace(/\s/g, "")
  //     const uuid=uuid()
  //     let list=test.split('.');
  //     let ext=list[1]
  //     let fileName=list[0]
  //     let file = selectedFile;
  //     let blob = file.slice(0, file.size, 'image/png');
  //     let newFile = new File([blob], fileName+"_"+uuid+"."+ext, {type: 'image/png'});
  //     return newFile
  // }

  //------------------------------Add file---------------------------------------------------------------
  const collectDocuments = async (documentsEmployee) => {
    console.log(documentsEmployee);

    return axios.post(
      "https://upkeep-be-uat.devtomaster.com/add_documents",
      {
        user_id: JSON.stringify(userID),
        documents: JSON.stringify(documentsEmployee),
      },
      {
        headers: {
          JWT: token,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const collectEmployeeDocumentFunction = (name, link) => {
    setCollectDocumentState({
      ...collectDocumentsState,
      [name]: link,
    });
    console.log(collectDocumentsState);
  };

  const onFileDrop = (e, name) => {
    let extension = e.target.files[0].name.split(".").pop();
    if (extension.toLowerCase() == "pdf" || extension.toLowerCase() == "png") {
      setSelectedFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
      // console.log(e.target.files[0].name);
      if (e.target.files[0].size / 1024 <= 2048) {
        let data = complete + 100 / noOfDocuments;
        if (data > 100) data = 100;
        setComplete(data);
        setDocument({
          ...document,
          [name]: e.target.value.split(/(\\|\/)/g).pop(),
        });
        //-------AWS uploadfile-----------------
        uploadFile(e.target.files[0], config)
          .then((data) => {
            console.log(data);
            collectEmployeeDocumentFunction(name, data.location);
          })
          .catch((err) => console.error(err));

        let documentCopy = [...collectEmployeeDocument];
        documentCopy.push({ [name]: e.target.files[0] });
        console.log(documentCopy);
        setCollectEmployeeDocument(documentCopy);
      } else {
        toast("File size limit exceeds!", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
        setDocument({
          ...document,
          [name]: "",
        });

        // setComplete(complete - 100.0/noOfDocuments);
        collectEmployeeDocumentFunction(name, document.location);
      }
    } else {
      toast("File type must PDF or PNG!", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    }
  };

  //------------------------------Remove file------------------------------------------------------------------
  function removeFile(name) {
    setComplete(complete - 100.0 / noOfDocuments);
    setDocument({
      ...document,
      [name]: "",
    });
    collectEmployeeDocumentFunction(name, document.location);
  }
  //------------------------------File upload---------------------------------------------------------------------
  function UploadInput({ name, label }) {
    return (
      <div className="py-5 md:py-12 lg:mr-10 md:mx-20 sm:mx-10 xs:mx-10">
        <label className="pb-2 font-bold pl-1 text-[#484848] py-2">
          {label}
        </label>
        {!document?.[name] ? (
          <div className="bg-[#B4C9FF] h-20 mt-2 md:mt-4 font-medium w-full rounded-lg border-dashed border-2 border-[#B4C9FF] flex justify-center items-center hover:cursor-pointer">
            <div className="absolute">
              <div className="flex items-center">
                <p className="block text-[#2F5FDA] font-bold p-[4px]">
                  Click to upload
                </p>
                <p className="block text-[#666666]">
                  or drag and drop <br />
                </p>
              </div>
              <p className="block text-[#959595] text-center">
                PDF / PNG file (Maximum 2 MB)
              </p>
            </div>
            <input
              type="file"
              className="h-full opacity-0 w-full"
              name=""
              disabled={onboarding_status === "Completed" ? true : false}
              // placeholder={existvalue[name].split('/').pop()}
              accept=".png,.pdf"
              onChange={(e) => {
                onFileDrop(e, name);
              }}
            />
          </div>
        ) : (
          <div className=" h-20 font-medium  lg:w-11/12 rounded-lg border-2 border-[#B4C9FF] flex">
            <div className="flex w-full  ">
              <div className="flex w-full items-center ml-6 ">
                <img className=" " src={pdfIcon} alt="" />
                <div className="  ml-2 w-5/6 ">
                  <p className="block text-sm font-semibold mx-1 items-center ">
                    {document?.[name]}
                  </p>
                  <div className="flex items-center">
                    <div className="h-2 w-full  bg-gray-300 rounded overflow-hidden mx-1 ">
                      <div
                        style={{ width: `${100}%` }}
                        className={`h-full  bg-green-600  `}
                      />
                    </div>
                    <div className="text-xs flex items-center  ">
                      100%
                      <span>
                        <MdClose
                          onClick={() => {
                            removeFile(name);
                          }}
                          size="1.4rem"
                          className=" ml-2 text-red-600 cursor-pointer"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {existvalue.length !== 0 &&
        existvalue[name] &&
        onboarding_status === "Completed" ? (
          <a
            className="flex items-center gap-x-2 mt-2 text-sm text-blue-600"
            href={existvalue[name]}
            target="_blank"
          >
            <AiFillEye></AiFillEye>
            View Documents
          </a>
        ) : !existvalue[name] && onboarding_status === "Completed" ? (
          <p className=" mt-2 text-sm text-red-600">Document Not Uploaded</p>
        ) : (
          ""
        )}
      </div>
    );
  }

  //---------------------------------------------Save Changes --------------------------------
  const saveChanges = () => {
    // if (complete < 100) {
    //   toast("Please fill the Fields", {
    //     type: "error",
    //     position: "top-right",
    //     autoClose: 2000,
    //     theme: "colored",
    //   });
    //   return;
    // }

    setLoading(true);
    collectDocuments(collectDocumentsState)
      .then(function (response) {
        console.log("response ", response);
        setLoading(false);
        if (response?.data && checkBox === true) {
          toast(response?.data.msg, {
            type: "success",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
          console.log("success");
        } else if (checkBox === false) {
          toast("Please fill the checkbox", {
            type: "error",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        } else {
          toast("Try again", {
            type: "info",
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast("Something went wrong!", {
          type: "error",
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
        console.log(error);
      });
  };

  //---------------------------------------------Submit---------------------------------------------------------
  const Submit = () => {
    // let inputField = document.querySelectorAll("input");

    // for (let i = 0; i < inputField.length; i++) {
    //   if (inputField[i].value === "") {
    //     toast("Please fill the Fields", {
    //       type: "error",
    //       position: "top-right",
    //       autoClose: 2000,
    //       theme: "colored",
    //     });
    //     return;
    //   }
    // }

    //setLoading(true);
    collectDocuments(collectDocumentsState);
    if (checkBox === false) {
      //setLoading(false);
      toast("Please fill the checkbox", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    } else if (complete < 100) {
      toast("Please fill all the fields", {
        type: "error",
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    } else {
      //setLoading(true);
      navigateDetails("/bankdetails", {
        state: { bank_details: location.state.bankdetails },
      });
    }
  };

  //---------------------------------------------THE MAIN COMPONENT---------------------------------------------

  return (
    <div className="font-OpenSans">
      {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}

      {/* Personal Info */}

      <div className="flex flex-col lg:grid grid-cols-3">
        {/*Section 1 -- Col 1 */}

        <div className="flex lg:flex flex-col ml-20 lg:mt-16 lg:ml-44 xs:ml-10">
          <h1 className="text-black font-bold text-2xl">Personal Documents</h1>
          <h2 className="hidden text-[#A1A1A1] lg:flex">
            Upload your personal documents here
          </h2>
        </div>

        {/*Section 1 -- Col 2 */}

        {/* Offer Letter */}
        <div className="flex flex-col lg:ml-48 items-center lg:col-span-2">
          <div className="flex flex-col w-full mx-auto mt-2 lg:mt-1" id="input">
            {!skeleton && (
              <Skeleton
                count={5}
                style={{
                  height: 60,
                  width: 800,
                  marginTop: "5rem",
                  marginLeft: "10rem",
                }}
              />
            )}
            {skeleton &&
              Object.keys(employeeDocument).map((key) => {
                //console.log(key);
                return <UploadInput label={key} name={key} />;
              })}
          </div>
        </div>
      </div>

      {/* Line in between */}

      <img
        className="scale-50 lg:mt-16 lg:flex lg:ml-44"
        src={LineTwo}
        alt=""
      />

      {/* FOOTER */}
      {onboarding_status !== "Completed" ? (
        <div className="flex flex-col px-auto lg:pl-24 items-center">
          <div className="hidden lg:ml-28 lg:mt-5">
            <img className="" src={LineTwo} alt="" />
          </div>

          <div className="flex flex-row mt-2 lg:ml-16 lg:pr-5 lg:px-5 md:px-20 xs:px-5">
            <input
              className="scale-105 lg:mt-6 lg:ml-4 lg:scale-125"
              type="checkbox"
              onClick={() => {
                setCheckBox(true);
              }}
            />
            <label className="mt-8 lg:mt-6 text-xs text-[#5F5F5F] pl-2 lg:text-base lg:w-full">
              I certify that the above facts are true to the best of my
              knowledge and I understand that I subject myself to disciplinary
              action in the event that the above facts are found to be
              falsified.
            </label>
          </div>
          <div className="flex  flex-row w-full justify-end lg:pr-10 md:pr-20 xs:pr-10">
             <button className="mt-4 w-72 h-12 ml-10 border-2 rounded-lg bg-[#3B69DF] hover:bg-[#1c377e] text-white lg:mt-4 lg:ml-6 lg:h-12 lg:w-96"
              onClick={() => {
                saveChanges(true);
                Submit();
              }}
            >
              {loading ? (
                <svg
                  class="inline mr-2 w-8 h-8  animate-spin text-white fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Save and Continue "
              )}
            </button>  
          </div>
        </div>
      ) : (
        <div className="flex flex-row justify-end">
          <button
            className="mt-4 h-12 border-2 rounded-lg bg-[#3B69DF] 
             hover:bg-[#1c377e] text-white lg:mt-4 xs:w-4/12 w-3/12"
            onClick={() => {
              navigateDetails("/bankdetails", {
                state: {
                  status: onboarding_status,
                  bank_details: location.state.bankdetails,
                },
              });
            }}
          >
            Next
          </button>
        </div>
      )}

      <ToastContainer />

      {/* Footer */}
      <Footer />

      {/* Main Div closing tag */}
    </div>
  );
};
export default InfoTwo;
