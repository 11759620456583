import { adminLogin, employeeLogin } from "./adminAuth";

export const baseAddress = "https://upkeep-be-dev.devtomaster.com";

export function setHRToken() {
  adminLogin({ email: "ketan@gmail.com", password: "ketan" }).then(function (
    response
  ) {
    console.log(response);

    if (response?.data.access_token) {
      localStorage.setItem("HRJWT", response?.data.access_token);
    }
  });
}

export function setEmpToken() {
  employeeLogin({ email: "devpranoy@gmail.com", password: "password" }).then(
    function (response) {
      console.log(response);

      if (response?.data.access_token) {
        localStorage.setItem("EMPJWT", response?.data.access_token);
      }
    }
  );
}
