import axios from "axios";

export function userLogin({ email, password }) {
  console.log({ email, password });
  
  return axios.post("https://upkeep-be-dev.devtomaster.com/employee_login", {
    email: email,
    password: password,

  });
}