import React from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";

function EditSection({
  title,
  subtitle,
  secondary,
  children,
  edit,
  onEditClick,
}) {
  return (
    <div>
      <div className="flex justify-between items-center font-OpenSans">
        <div>
          <p className="text-lg text-[#000000] font-semibold flex items-center">
            {title} <span className="mx-1" />
            {edit && (
              <MdOutlineModeEditOutline
                onClick={onEditClick}
                className="cursor-pointer"
                size="1.2rem"
              />
            )}
          </p>
          <p className="text-sm text-[#808080] font-normal">{subtitle}</p>
        </div>
        <p className="text-sm  font-semibold underline text-blue-500 cursor-pointer">
          {secondary}
        </p>
      </div>
      <div className="p-2">{children}</div>
    </div>
  );
}

export default EditSection;
